import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Typography, Button, Card, CardContent, Snackbar, LinearProgress, Alert } from '@mui/material';
import { format, isToday, isPast } from 'date-fns';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { styled } from '@mui/system';
import ApiServices from '../../../Utils/ApiServices/ApiServices';

const ExerciseDetailsPreview = () => {
  const { id } = useParams();
  const [exercise, setExercise] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [submission, setSubmission] = useState(null);

  useEffect(() => {
    ApiServices.get(`/api/exercises/${id}/`)
      .then((response) => setExercise(response.data.data))
      .catch((error) => console.error('Error fetching exercise details:', error));

    const student_id = JSON.parse(sessionStorage.getItem("LoginUserDetails")).id;
    ApiServices.get(`/api/exercises/${id}/students/${student_id}/submissions/`)
      .then((response) => setSubmission(response.data))
      .catch((error) => console.error('Error fetching submission details:', error));
  }, [id]);

  if (!exercise) return <Typography>Loading...</Typography>;

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = () => {
    const student_id = JSON.parse(sessionStorage.getItem("LoginUserDetails")).id;
    const formData = new FormData();
    formData.append('exercise_id', id);
    formData.append('file', file);
    formData.append('student_id', student_id);

    ApiServices.post('/api/submissions/', formData, {
        'Content-Type': 'multipart/form-data' ,
    })
      .then(() => {
        setSnackbar({ open: true, message: 'File uploaded successfully!', severity: 'success' });
        setUploadProgress(0);
        setFile(null);
      })
      .catch(() => {
        setSnackbar({ open: true, message: 'File upload failed.', severity: 'error' });
        setUploadProgress(0);
      });
  };

  const isDueToday = isToday(new Date(exercise.due_date));
  const isDuePast = isPast(new Date(exercise.due_date)) && !isToday(new Date(exercise.due_date));
  const formattedDueDate = format(new Date(exercise.due_date), 'dd/MM/yyyy');
  const formattedCreatedDate = format(new Date(exercise.created_at), 'dd/MM/yyyy');

  return (
    <div>
      <Card style={styles.card}>
        <CardContent>
          <Typography variant="h4" style={styles.title}>
            <AssignmentIcon style={styles.icon} /> {exercise.title}
          </Typography>
          <Typography variant="body1" style={styles.description}>
            {exercise.description}
          </Typography>

          <div style={styles.infoSection}>
            <div style={styles.dateRow}>
              <Typography style={styles.dateLabel}>Assigned Date:</Typography>
              <Typography style={styles.date}>{formattedCreatedDate}</Typography>
            </div>
            <div style={styles.dateRow}>
              <Typography style={styles.dateLabel}>Due Date:</Typography>
              <Typography
                style={{
                  ...styles.date,
                  color: isDuePast ? 'red' : isDueToday ? 'yellow' : '#333',
                  animation: isDueToday ? 'blinking 1s infinite' : 'none',
                }}
              >
                {formattedDueDate}
              </Typography>
            </div>
          </div>

          {exercise.file && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<FolderOpenIcon />}
              style={styles.fileButton}
              onClick={() => window.open(exercise.file, '_blank')}
            >
              Show File
            </Button>
          )}
        </CardContent>
      </Card>

      {submission && submission.file && (
        <Card style={styles.card}>
          <CardContent>
            <Typography variant="h5" style={styles.title}>
              Submitted Exercise
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              style={styles.previewButton}
              onClick={() => window.open(submission.file, '_blank')}
            >
              Preview
            </Button>
          </CardContent>
        </Card>
      )}

      <Card style={styles.card}>
        <CardContent>
          <Typography variant="h4" style={styles.title}>
            Upload Your Exercise
          </Typography>
        </CardContent>
        <input
          type="file"
          onChange={handleFileChange}
          style={{ marginTop: '20px' }}
        />

        {file && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleFileUpload}
            style={styles.uploadButton}
            disabled={!file}
          >
            {uploadProgress > 0 ? <LinearProgress variant="determinate" value={uploadProgress} /> : 'Upload File'}
          </Button>
        )}
      </Card>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

const styles = {
  card: {
    margin: '20px',
    padding: '20px',
    backgroundColor: '#FFF9E6',
    borderRadius: '15px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    color: '#FF6F61',
    fontSize: '2rem',
    fontFamily: 'Comic Sans MS, sans-serif',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
  icon: {
    fontSize: '2.5rem',
    color: '#FFD700',
  },
  description: {
    color: '#444',
    fontSize: '1.2rem',
    fontFamily: 'Arial, sans-serif',
    marginTop: '15px',
    marginBottom: '15px',
  },
  infoSection: {
    marginTop: '20px',
  },
  dateRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  dateLabel: {
    color: '#555',
    fontSize: '1rem',
    fontFamily: 'Comic Sans MS, sans-serif',
  },
  date: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  fileButton: {
    marginTop: '20px',
    fontSize: '1rem',
    backgroundColor: '#FFAB91',
    color: '#FFF',
  },
  previewButton: {
    marginTop: '20px',
    fontSize: '1rem',
    backgroundColor: '#FFAB91',
    color: '#FFF',
  },
  uploadButton: {
    marginTop: '20px',
    fontSize: '1rem',
    color: '#FFF',
  },
};

export default ExerciseDetailsPreview;
