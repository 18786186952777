import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Box,
  Grid,
  Paper,
  Button,
  Divider,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  CircularProgress 
} from "@mui/material";
import axios from "axios";
import ApiServices from "../../../Utils/ApiServices/ApiServices";
import { Visibility as VisibilityIcon } from "@mui/icons-material";


const CourseDetail = () => {
  const { id } = useParams(); // Get course ID from the URL
  const [course, setCourse] = useState(null);
  const [subCourseData, setsubCourseData] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(false); // New state to check enrollment status
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [userEnrolled, setUserEnrolled] = useState(false);
  const [isStudent, setIsStudent] = useState(false);

  useEffect(() => {
    // Fetch course details by ID
    const fetchCourse = async () => {
      try {
        const response = await ApiServices.get(
          `/api/courses/${id}/`
        );
        setCourse(response.data.data);
        setsubCourseData(response.data.data.subcourses);

        setLoading(false);

        const LoginUserdata = JSON.parse(
          sessionStorage.getItem("LoginUserDetails")
        );
        const enrollmentResponse = await ApiServices.get(
          `/api/user/${LoginUserdata.id}/courses/`
        );
        if (enrollmentResponse.data.data.length > 0) {
          setIsEnrolled(true); // If enrolled, update the state
        }
      } catch (error) {
        // setError("Failed to fetch course details");
        setLoading(false);
      }
    };

    fetchCourse();
  }, [id]);

  useEffect(() => {
    // Check if user is a student and fetch their courses
    const checkUserEnrollment = async () => {
      const LoginUserdata = JSON.parse(
        sessionStorage.getItem("LoginUserDetails")
      );
      if (LoginUserdata && LoginUserdata.user_type === "student") {
        setIsStudent(true);
        const studentId = LoginUserdata.id; // Assume ID is part of LoginUserdata

        try {
          const response = await ApiServices.get(
            `/api/user/${studentId}/courses/`
          );
          const enrolledCourses = response.data.data;
          const isEnrolledInCourse = enrolledCourses.some(
            (course) => course.course.id === parseInt(id)
          );
          if (isEnrolledInCourse) {
            setUserEnrolled(true);
          }
        } catch (error) {
          console.error("Failed to fetch enrolled courses:", error);
        }
      }
    };

    checkUserEnrollment();
  }, [id]);

  const handleEnroll = async () => {
    if (isEnrolled) return; // Prevent re-enrollment

    try {
      function getCurrentDate() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
        const day = String(now.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
      }

      const LoginUserdata = JSON.parse(
        sessionStorage.getItem("LoginUserDetails")
      );
      const joiningDate = getCurrentDate();
      const payload = {
        student: LoginUserdata.email,
        course: id,
        joining_date: joiningDate,
        expiry_date: null,
        is_active: true,
        is_paid: true,
        amount_paid: course.charges || "0.00",
      };

      await ApiServices.post("/api/assigned-courses/", payload);
      setIsEnrolled(true); // Update the state to reflect enrollment
      setSnackbar({
        open: true,
        message: "Enrolled successfully!",
        severity: "success",
      });
      setUserEnrolled(true); // Update state to reflect enrollment
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to enroll in the course",
        severity: "error",
      });
    }
  };

const [openDialog, setOpenDialog] = useState(false); // Dialog state
const [userDetails, setUserDetails] = useState({
  first_name: "",
  last_name: "",
  mobile: "",
  email: "",
  address: "",
});
const [errors, setErrors] = useState({}); // Form validation errors

const validateForm = () => {
  let formErrors = {};
  if (!userDetails.first_name) formErrors.first_name = "First name is required";
  if (!userDetails.last_name) formErrors.last_name = "Last name is required";
  if (!userDetails.mobile || userDetails.mobile.length !== 10) {
    formErrors.mobile = "Valid mobile number is required";
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!userDetails.email || !emailRegex.test(userDetails.email)) {
    formErrors.email = "Valid email is required";
  }
  if (!userDetails.address) formErrors.address = "Address is required";
  setErrors(formErrors);
  return Object.keys(formErrors).length === 0; // Return true if no errors
};

const handleDownloadBrochure = () => {
  const LoginUserdata = sessionStorage.getItem("LoginUserDetails");
  if (!LoginUserdata) {
    setOpenDialog(true); // Open the dialog if user is not logged in
  } else {
    window.open(course.brochure, "_blank");
  }
};

const handleDialogClose = () => {
  setOpenDialog(false);
  setErrors({});
};



const handleInputChange = (e) => {
  const { name, value } = e.target;
  setUserDetails({ ...userDetails, [name]: value });
};

const [broucherloading,setbroucherloading] = useState(false)
const handleFormSubmit = async () => {
  if (!validateForm()) return; // Stop if form is invalid

  const payload = {
    ...userDetails,
    course: id, // Add course ID
  };

  try {
    setbroucherloading(true)
    await ApiServices.post("/api/enquiries/create/", payload);
    setSnackbar({
      open: true,
      message: "Details submitted successfully! Downloading brochure...",
      severity: "success",
    });
    setOpenDialog(false);
    setbroucherloading(false) // Close dialog on success
    window.open(course.brochure, "_blank"); // Download brochure after success
  } catch (error) {
    setbroucherloading(false)
    setSnackbar({
      open: true,
      message: "Failed to submit details. Please try again.",
      severity: "error",
    });
  }
};

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>{error}</Typography>;
  }

  if (!course) {
    return <Typography>No course data found.</Typography>;
  }

  const {
    title,
    subtitle,
    intro,
    author,
    course_type,
    charges,
    duration,
    image,
    brochure,
    description,
    is_course_verified,

  } = course;

  return (
    <Box sx={{ padding: "20px" }}>
      <Paper elevation={3} sx={{ padding: "20px", marginBottom: "20px" }}>
        {/* Course Image and Title Section */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <img
              src={image}
              alt={title}
              style={{ width: "100%", borderRadius: "10px" }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h4"
              gutterBottom
              color="primary"
              fontWeight="bold"
            >
              {title}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {subtitle}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {intro}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Duration: {duration}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Type: {course_type}
            </Typography>
            {charges && (
              <Typography variant="body2" color="textSecondary">
                Charges: {charges}
              </Typography>
            )}
            <Box sx={{ marginTop: "20px" }}>
              <Button
                variant="contained"
                color="primary"
                // href={brochure}
                // target="_blank"
                onClick={handleDownloadBrochure}
                
              >
                Download Brochure
              </Button>
              
              {isStudent && (
                <Button
                  sx={{ marginLeft: "10px" }}
                  variant="contained"
                  color="secondary"
                  onClick={handleEnroll}
                  disabled={userEnrolled} // Disable if already enrolled
                >
                  {userEnrolled ? "Already Enrolled" : "Enroll The Course"}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* Author Information */}
      <Paper elevation={3} sx={{ padding: "20px", marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          Instructor: {author.first_name} {author.last_name}
        </Typography>
        <Typography variant="body1">Email: {author.email}</Typography>
        <Typography variant="body1">Phone: {author.phone_number}</Typography>
      </Paper>

      {/* Course Description */}
      <Paper elevation={3} sx={{ padding: "20px", marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          Course Description
        </Typography>
        {description.map((desc, index) => (
          <Box key={index} sx={{ marginBottom: "15px" }}>
            <Typography variant="body1" fontWeight="bold">
              Section {index + 1}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {desc.content}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Objectives: {desc.objectives}
            </Typography>
            <Divider sx={{ marginY: "10px" }} />
          </Box>
        ))}
      </Paper>

      {subCourseData !== null && (
  <Paper elevation={3} sx={{ padding: "20px", marginBottom: "20px" }}>
  <Typography variant="h5" gutterBottom>
                  Sub Courses 
                </Typography>
    {subCourseData.map((desc, index) => (
      <Box key={index} sx={{ marginBottom: "15px" }}>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Typography variant="body1" fontWeight="bold">
              {desc.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Objectives: {desc.description}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "right" }}>
            <Button
              variant="text"
              color="primary"
              startIcon={<VisibilityIcon />}
              onClick={() => window.open(desc.file, "_blank")}
            >
              Preview
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ marginY: "10px" }} />
      </Box>
    ))}
  </Paper>
)}

 <Dialog open={openDialog} onClose={handleDialogClose}>
<DialogTitle>Enter Your Details to Download the Brochure!</DialogTitle>
<DialogContent>
  <TextField
    label="First Name"
    name="first_name"
    value={userDetails.first_name}
    onChange={handleInputChange}
    fullWidth
    margin="normal"
    error={!!errors.first_name}
    helperText={errors.first_name}
  />
  <TextField
    label="Last Name"
    name="last_name"
    value={userDetails.last_name}
    onChange={handleInputChange}
    fullWidth
    margin="normal"
    error={!!errors.last_name}
    helperText={errors.last_name}
  />
  <TextField
    label="Mobile"
    name="mobile"
    value={userDetails.mobile}
    onChange={handleInputChange}
    fullWidth
    margin="normal"
    error={!!errors.mobile}
    helperText={errors.mobile}
  />
  <TextField
    label="Email"
    name="email"
    value={userDetails.email}
    onChange={handleInputChange}
    fullWidth
    margin="normal"
    error={!!errors.email}
    helperText={errors.email}
  />
  <TextField
    label="Address"
    name="address"
    value={userDetails.address}
    onChange={handleInputChange}
    fullWidth
    margin="normal"
    error={!!errors.address}
    helperText={errors.address}
  />
</DialogContent>
<DialogActions>
  <Button onClick={handleDialogClose}>Cancel</Button>
  {/* <Button onClick={handleFormSubmit} variant="contained" color="primary">
    Submit
  </Button> */}
  <Button
  variant="contained"
  color="primary"
  onClick={handleFormSubmit}
  disabled={broucherloading} 
>
  {broucherloading ? (
    <>
      <CircularProgress size={24} style={{ color: "white", marginRight: 8 }} />
      Submitting
    </>
  ) : (
    "Submit"
  )}
</Button>
</DialogActions>
</Dialog> 

      {/* Course Verification Status */}
     

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CourseDetail;

