import React, { useState } from 'react';
import { TextField, Button, Snackbar, Alert, Paper, Typography } from '@mui/material';
import ApiServices from '../../../Utils/ApiServices/ApiServices'; // Assuming ApiServices is set up for API calls
import { useNavigate } from 'react-router-dom';

const CreateTeacherBatches = () => {
  const [batchName, setBatchName] = useState('');
  const [meetingLink, setMeetingLink] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const navigate = useNavigate();
  // Fetch teacher email from sessionStorage
  const userDetails = JSON.parse(sessionStorage.getItem('LoginUserDetails'));
  const teacherEmail = userDetails?.email || '';

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (!batchName || !startDate || !endDate) {
      setSnackbar({ open: true, message: 'All fields are required!', severity: 'error' });
      return;
    }

    // Prepare payload
    const payload = {
      batch_name: batchName,
      // meeting_link: meetingLink,
      meeting_link: null,
      start_date: startDate,
      expected_end_date: endDate,
      is_active: isActive,
      teacher_email: teacherEmail
    };

    // Make API call to create batch
    try {
      const response = await ApiServices.post('/api/batches/', payload);
      if (response) {
        setSnackbar({ open: true, message: 'Batch created successfully!', severity: 'success' });
        // Clear the form fields
        setBatchName('');
        setMeetingLink('');
        setStartDate('');
        setEndDate('');

        setTimeout(() => {
          navigate(-1); // Go back to the previous page
        }, 2000); 
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to create batch!', severity: 'error' });
      console.error('Error creating batch:', error);
    }
  };

  // Handle Snackbar close
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Paper elevation={3} style={{ padding: '20px', margin: '20px auto', maxWidth: '500px' }}>
      <Typography variant="h5" align="center" gutterBottom>
        Create Teacher Batch
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Batch Name"
          variant="outlined"
          fullWidth
          required
          value={batchName}
          onChange={(e) => setBatchName(e.target.value)}
          margin="normal"
        />
        {/* <TextField
          label="Meeting Link"
          variant="outlined"
          fullWidth
          required
          value={meetingLink}
          onChange={(e) => setMeetingLink(e.target.value)}
          margin="normal"
        /> */}
        <TextField
          label="Start Date"
          type="date"
          variant="outlined"
          fullWidth
          required
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          type="date"
          variant="outlined"
          fullWidth
          required
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Teacher Email"
          variant="outlined"
          fullWidth
          value={teacherEmail}
          disabled
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          style={{ marginTop: '20px' }}
        >
          Create Batch
        </Button>
      </form>

      {/* Snackbar for success and error messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default CreateTeacherBatches;
