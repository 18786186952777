import React, { useState, useEffect } from "react";
import axios from "axios"; // Importing axios
import {
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  Slider,
  List,
  ListItem,
  Paper,
  FormControl,
  Select,
  InputLabel,
  Chip,
  AppBar,
  Toolbar,
} from "@mui/material";
import { styled } from '@mui/system';
import ApiServices from "../../../Utils/ApiServices/ApiServices";

// Styled components for better aesthetics
const Container = styled(Box)( {
  padding: "25px",
  backgroundColor: "#E3F2FD",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
});

const WordDisplay = styled(Typography)( {
  fontSize: '3rem',
  color: "#D32F2F",
  textAlign: "center",
  fontWeight: "bold",
});

const SpeedSlider = styled(Slider)( {
  color: "#388E3C",
  '& .MuiSlider-thumb': {
    backgroundColor: '#fff',
    border: '2px solid #388E3C',
  },
  '& .MuiSlider-track': {
    backgroundColor: '#BBDEFB',
  },
});

const ContentInput = styled(TextField)( {
  marginTop: "20px",
  height: "auto",
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#BBDEFB',
    },
    '&:hover fieldset': {
      borderColor: '#42A5F5',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#388E3C',
    },
  },
});

const ActionButton = styled(Button)( {
  padding: "10px 20px",
  fontSize: "1.2rem",
  borderRadius: "20px",
});

const LevelSelect = styled(Select)( {
  backgroundColor: "#BBDEFB",
  '&:hover': {
    backgroundColor: "#90CAF9",
  },
});

// SpeedReading Component
const SpeedReading = () => {
  const [words, setWords] = useState(["Click on 'Start'"]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(300);
  const [history, setHistory] = useState([]);
  const [readingCompleted, setReadingCompleted] = useState(false);
  const [contentList, setContentList] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState("all"); // Default level to show all

  useEffect(() => {
    const fetchContentData = async () => {
      try {
        const response = await ApiServices.get("/api/speedreading/data/");
        const data = response.data.data;
        const activeContent = data.filter(item => item.active);

        // If no active content, set default content
        if (activeContent.length === 0) {
          setContentList([{
            title: "Luddy Buddy",
            content: "Luddy Buddy teaches kids coding and various school subjects to help them grow in today's education system. With engaging methods, children learn important skills while having fun!",
            level: "easy",
            active: true,
          }]);
        } else {
          setContentList(activeContent);
        }
        
        setSelectedContent(activeContent[0] || null); // Set default selected content
      } catch (error) {
        console.error("Error fetching content data:", error);
      }
    };

    fetchContentData();
  }, []);

  useEffect(() => {
    if (selectedContent) {
      setWords(selectedContent.content.split(" "));
      setCurrentWordIndex(0);
    }
  }, [selectedContent]);

  useEffect(() => {
    let timer;
    if (isPlaying) {
      timer = setInterval(() => {
        setCurrentWordIndex((prevIndex) => {
          const newIndex = prevIndex + 1;
          if (newIndex < words.length) {
            return newIndex;
          } else {
            setIsPlaying(false);
            setReadingCompleted(true);
            return prevIndex;
          }
        });
      }, speed);
    }
    return () => clearInterval(timer);
  }, [isPlaying, speed, words]);

  useEffect(() => {
    if (readingCompleted) {
      setHistory((prevHistory) => [...prevHistory, selectedContent.title]);
      setReadingCompleted(false);
    }
  }, [readingCompleted, selectedContent]);

  const startReading = () => {
    setIsPlaying(true);
  };

  const pauseReading = () => {
    setIsPlaying(false);
  };

  const resetReading = () => {
    setIsPlaying(false);
    setCurrentWordIndex(0);
  };

  const handleSpeedChange = (event, newValue) => {
    setSpeed(1000 - newValue);
  };

  const handleLevelChange = (event) => {
    const level = event.target.value;
    setSelectedLevel(level);
  };

  const handleTitleClick = (content) => {
    setSelectedContent(content);
  };

  const handleContentChange = (event) => {
    const updatedContent = event.target.value;
    setSelectedContent((prev) => ({ ...prev, content: updatedContent }));
    setWords(updatedContent.split(" ")); // Update words for reading
    setCurrentWordIndex(0); // Reset the index to start reading from the beginning
  };

  const filteredContentList =
    selectedLevel === "all"
      ? contentList
      : contentList.filter((item) => item.level === selectedLevel);

  return (
    <Container>
      <AppBar position="static" sx={{ backgroundColor: "#42A5F5" }}>
        <Toolbar>
          <Typography variant="h5" color="#fff">
            Speed Reading Fun!
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container spacing={3} sx={{ textAlign: "center" }}>
        <Grid item xs={12} md={7}>
          <Box sx={{ marginBottom: "20px" }}>
            <WordDisplay>
              {words[currentWordIndex] || ""}
            </WordDisplay>
          </Box>
          <Typography variant="h6" color="#D32F2F" fontWeight="bold">
            Selected Content: {selectedContent ? selectedContent.title : "None"}
          </Typography>
          <ContentInput
            fullWidth
            multiline
            rows={4}
            value={selectedContent ? selectedContent.content : ""}
            onChange={handleContentChange}
          />
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginY: 2 }}>
            <Typography variant="h6" color="primary" fontWeight="bold">
              Speed (ms):
            </Typography>
            <SpeedSlider
              value={1000 - speed}
              onChange={handleSpeedChange}
              min={10}
              max={1000}
              sx={{ marginLeft: "20px", width: "200px" }}
            />
          </Box>
          <Box sx={{ display: "flex", gap: "10px", justifyContent: "center", marginTop: "20px" }}>
            <ActionButton
              variant="contained"
              color="success"
              onClick={startReading}
              disabled={!selectedContent || isPlaying}
              sx={{ backgroundColor: "#388E3C" }}
            >
              Start Reading!
            </ActionButton>
            <ActionButton
              variant="contained"
              color="error"
              onClick={pauseReading}
              disabled={!isPlaying}
              sx={{ backgroundColor: "#D32F2F" }}
            >
              Pause
            </ActionButton>
            <ActionButton variant="contained" color="primary" onClick={resetReading}>
              Reset
            </ActionButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography
            variant="h5"
            color="#D32F2F"
            fontWeight="bold"
            sx={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}
          >
            Available Content
          </Typography>
          {filteredContentList.length === 0 ? (
            <Typography>No content available for the selected level.</Typography>
          ) : (
            <List sx={{ padding: 0 }}>
              {filteredContentList.map((content, index) => (
                <ListItem
                  key={index}
                  component={Paper}
                  sx={{
                    marginBottom: "10px",
                    padding: "10px",
                    cursor: "pointer",
                    border: "1px solid #BBDEFB",
                    borderRadius: "8px",
                    backgroundColor: "#E1F5FE",
                    "&:hover": { backgroundColor: "#B3E5FC" },
                  }}
                  onClick={() => handleTitleClick(content)}
                >
                  <Chip label={content.level} color="primary" sx={{ marginRight: "10px" }} />
                  <Typography variant="h6">{content.title}</Typography>
                </ListItem>
              ))}
            </List>
          )}
    
        </Grid>
      </Grid>
    </Container>
  );
};

export default SpeedReading;
