import React, { useState } from 'react';
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Typography,
  Box,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs'; // for managing dates
import axios from 'axios';
import ApiServices from '../../../Utils/ApiServices/ApiServices';

const CreateExercise = () => {
  const { id, title } = useParams(); // Assuming 'id' is the course ID and 'title' is the course title
  const navigate = useNavigate();

  // Get today's date in YYYY-MM-DD format
  const today = dayjs().format('YYYY-MM-DD');

  const [exerciseData, setExerciseData] = useState({
    title: '',
    description: '',
    due_date: today, // Set default date as today's date
  });
  const [file, setFile] = useState(null); // Single file state
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  const handleChange = (e) => {
    setExerciseData({ ...exerciseData, [e.target.name]: e.target.value });
  };

  // Handle file input change for single file
  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Only the first file selected
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!exerciseData.title || !exerciseData.description || !exerciseData.due_date) {
      setAlertMessage('All fields are required.');
      setAlertSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true); // Show progress on button

    const formData = new FormData();
    formData.append('course', id); // Using the course ID from params
    formData.append('title', exerciseData.title);
    formData.append('description', exerciseData.description);
    formData.append('due_date', `${exerciseData.due_date}T00:00:00Z`);

    if (file) {
      formData.append('file', file); // Append the single file
    }

    try {
      const response = await ApiServices.post('/api/exercises/', formData, {

          'Content-Type': 'multipart/form-data',

      });

      setAlertMessage('Exercise created successfully!');
      setAlertSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setAlertMessage('Error creating exercise.');
      setAlertSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Hide progress after request is done
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Create Exercise for {title}
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          label="Exercise Title"
          variant="outlined"
          fullWidth
          name="title"
          value={exerciseData.title}
          onChange={handleChange}
          required
          margin="normal"
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          name="description"
          value={exerciseData.description}
          onChange={handleChange}
          required
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          label="Due Date"
          variant="outlined"
          fullWidth
          name="due_date"
          type="date" // Only date, no time
          value={exerciseData.due_date}
          onChange={handleChange}
          required
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: {
              min: today, // Restrict past dates
            },
          }}
        />

        {/* Single File Upload Section */}
        <Typography variant="h6" gutterBottom mt={2}>
          Attach File
        </Typography>
        <TextField
          type="file"
          onChange={handleFileChange}
          inputProps={{
            accept: '.pdf,.doc,.docx,.png,.jpg,.jpeg', // Restrict file types if needed
          }}
          fullWidth
          margin="normal"
        />

        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : 'Create Exercise'}
          </Button>
        </Box>
      </form>

      {/* Snackbar for success/error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={alertSeverity}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CreateExercise;
