import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ExitToApp, AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import Logo from "../../../Assets/cosmos  Logo.png";
import "./Navbar.css"

function WebNavbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const isLargeScreen = useMediaQuery("(min-width:1024px)");
  const LoginUser = sessionStorage.getItem("LoginUserDetails")
  const LoginUserDetails = JSON.parse(LoginUser)

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    setLogoutDialogOpen(true);
    handleClose();
  };

  const handleLogoutConfirm = () => {
    setIsLoggingOut(true);
    setTimeout(() => {
      sessionStorage.clear();
      setIsLoggingOut(false);
      setLogoutDialogOpen(false);
      navigate("/");
    }, 3000);
  };

  const handleLogoutCancel = () => {
    setLogoutDialogOpen(false);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem
          button
          component={Link}
          to="/"
          sx={{
            color: location.pathname === "/" ? "#ff6600" : "inherit",
          }}
        >
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/contact-us"
          sx={{
            color: location.pathname === "/contact-us" ? "#ff6600" : "inherit",
          }}
        >
          <ListItemText primary="Contact" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/about-us"
          sx={{
            color: location.pathname === "/about-us" ? "#ff6600" : "inherit",
          }}
        >
          <ListItemText primary="About Us" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/our-courses"
          sx={{
            color: location.pathname === "/our-courses" ? "#ff6600" : "inherit",
          }}
        >
          <ListItemText primary="Courses" />
        </ListItem>
        {/* <ListItem
          button
          component={Link}
          to="/speed-reading"
          sx={{
            color: location.pathname === "/speed-reading" ? "#ff6600" : "inherit",
          }}
        >
          <ListItemText primary="Speed Reading" />
        </ListItem> */}
        <ListItem
          button
          component={Link}
          to="/typing-test"
          sx={{
            color: location.pathname === "/typing-test" ? "#ff6600" : "inherit",
          }}
        >
          <ListItemText primary="Typing Test" />
        </ListItem>
      </List>
      <Divider />
    </Box>
  );

  const isUserLoggedIn = !!sessionStorage.getItem("LoginUserDetails");

  return (
    <>
      <AppBar
        position="static"
        sx={{
          // backgroundColor: "#00308F",
          // color: "white",
          // borderBottom: "1px solid #2a2a2a",
          backgroundColor: "#48CFCB",
          color: "white",
          borderBottom: "1px solid #2a2a2a",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center">
            {!isLargeScreen && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ marginRight: 2 }}
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <img
              src={Logo}
              alt="Hospital Logo"
              style={{ maxWidth: "100%", maxHeight: 65 }}
            />
          </Box>
          {isLargeScreen && (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Typography
                variant="body1"
                component={Link}
                to="/"
                sx={{
                  color: location.pathname === "/" ? "#ff6600" : "white",
                  textDecoration: "none",
                }}
              >
                Home
              </Typography>
              <Typography
                variant="body1"
                component={Link}
                to="/contact-us"
                sx={{
                  color: location.pathname === "/contact-us" ? "#ff6600" : "white",
                  textDecoration: "none",
                }}
              >
                Contact
              </Typography>
              <Typography
                variant="body1"
                component={Link}
                to="/about-us"
                sx={{
                  color: location.pathname === "/about-us" ? "#ff6600" : "white",
                  textDecoration: "none",
                }}
              >
                About Us
              </Typography>
              <Typography
                variant="body1"
                component={Link}
                to="/our-courses"
                sx={{
                  color: location.pathname === "/our-courses" ? "#ff6600" : "white",
                  textDecoration: "none",
                }}
              >
                Courses
              </Typography>
              {/* <Typography
                variant="body1"
                component={Link}
                to="/speed-reading"
                sx={{
                  color: location.pathname === "/speed-reading" ? "#ff6600" : "white",
                  textDecoration: "none",
                }}
              >
                Speed Reading
              </Typography> */}

              <Typography
                variant="body1"
                component={Link}
                to="/typing-test"
                sx={{
                  color: location.pathname === "/typing-test" ? "#ff6600" : "white",
                  textDecoration: "none",
                }}
              >
                Typing Test
              </Typography>
            </Box>
          )}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="user account"
            onClick={handleMenu}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {isUserLoggedIn ? (
              <>
                <MenuItem onClick={handleClose}>
                  <Link
                    to="/client/user-profile"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                   {LoginUserDetails.first_name} {" "} {LoginUserDetails.last_name}
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleLogoutClick}>
                  <Button startIcon={<ExitToApp />} style={{ color: "red" }}>
                    Logout
                  </Button>
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem onClick={handleClose}>
                  <Link
                    to="/student-sign-up"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Sign Up
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link
                    to="/sign-in"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Sign In
                  </Link>
                </MenuItem>
              </>
            )}
          </Menu>
        </Toolbar>
      </AppBar>

      {/* Drawer for small screens */}
      <Drawer anchor="top" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerContent}
      </Drawer>

      {/* Logout confirmation dialog */}
      <Dialog
        open={logoutDialogOpen}
        onClose={handleLogoutCancel}
        aria-labelledby="logout-dialog-title"
      >
        <DialogTitle id="logout-dialog-title">Logout Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleLogoutCancel}
            sx={{ color: theme.palette.secondary.main }}
          >
            No
          </Button>
          <Button
            onClick={handleLogoutConfirm}
            sx={{ color: theme.palette.primary.main }}
          >
            {isLoggingOut ? <CircularProgress size={24} /> : "Yes"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default WebNavbar;
