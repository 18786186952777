import { useMediaQuery } from '@mui/material';
import React from 'react'
import StudentSidebar from '../../Container/StudentSidebar.js/StudentSidebar';
import { Route, Routes } from 'react-router-dom';
import StudentPrivateRoute from '../PrivateRoutes/StudentPrivateRoute';
import CourseDetail from '../../Components/WebSitePages/CourseDetail/CourseDetail';
import EnrolledCourses from '../../Components/StudentSection/EnrolledCourses/EnrolledCourses';
import StudentDashboard from '../../Components/StudentSection/StudentDashboard/StudentDashboard';
import CoursesPage from '../../Components/WebSitePages/CoursesPage/CoursesPage';
import StudentBatches from '../../Components/StudentSection/StudentBatches/StudentBatches';
import AddNewPdf from '../../Components/StudentSection/PdfManagement/AddNewPdf';
import CodeSandbox from '../../Components/CodeSandbox/CodeSandbox';
import LectureSessionsList from '../../Components/TeacherSection/LectureSessions/LectureSessionsList';
import SessionDetails from '../../Components/TeacherSection/LectureSessions/SessionDetails';
import ExerciseDetails from '../../Components/TeacherSection/Exercises/ExerciseDetails';
import AllExercises from '../../Components/StudentSection/Exercises/AllExercises';
import ExerciseDetailsPreview from '../../Components/StudentSection/Exercises/ExerciseDetailsPreview';
import SpeedReading from '../../Components/WebSitePages/SpeedReading/SpeedReading';

const StudentRoutes = () => {
  const isLargeScreen = useMediaQuery("(min-width:1441px)");

  return (
    <div>
      <StudentSidebar/>
      <div
        style={{
          marginLeft: isLargeScreen ? "250px" : "0",
          flexGrow: 1,
          padding: "10px",
        }}
      >
        <Routes>
          <Route
            path="/my-courses"
            element={<StudentPrivateRoute element={<EnrolledCourses />} />}
          />
          <Route
            path="/my-projects"
            element={<StudentPrivateRoute element={<StudentDashboard />} />}
          />
          <Route
            path="/user-profile"
            element={<StudentPrivateRoute element={<StudentDashboard />} />}
          />
          <Route
            path="/course-details/:id/:title"
            element={<StudentPrivateRoute element={<CourseDetail />} />}
          />
          <Route
            path="/my-course-batches"
            element={<StudentPrivateRoute element={<StudentBatches />} />}
          />
          <Route
            path="/available-courses"
            element={<StudentPrivateRoute element={<CoursesPage />} />}
          />
          <Route
            path="/typing-pdf"
            element={<StudentPrivateRoute element={<AddNewPdf />} />}
          />
          <Route
            path="/exercises-handling"
            element={<StudentPrivateRoute element={<CodeSandbox />} />}
          />
           <Route
            path="/sessions-of-batch/:id/:title"
            element={<StudentPrivateRoute element={<LectureSessionsList />} />}
          />
           <Route
            path="/sessions-details/:id/:title/:sessionId"
            element={<StudentPrivateRoute element={<SessionDetails />} />}
          />
           <Route
            path="/course-exercises-details/:id/:title/:courseID"
            element={<StudentPrivateRoute element={<ExerciseDetailsPreview />} />}
          />
           <Route
            path="/course-exercises"
            element={<StudentPrivateRoute element={<AllExercises />} />}
          />
           <Route
            path="/speed-reading"
            element={<StudentPrivateRoute element={<SpeedReading />} />}
          />
        </Routes>
      </div>
    </div>
  )
}

export default StudentRoutes