import React from 'react';

const ServiceTerms = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1>Terms of Use for Luddy Buddy</h1>

      <p>
        Welcome to Luddy Buddy! By accessing or using our platform, you agree to the following Terms of Use.
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        These Terms of Use govern your access to and use of Luddy Buddy. By registering, accessing, or using 
        the App, you agree to these terms. If you disagree with any part, please refrain from using the App.
      </p>

      <h2>2. User Registration and Account Security</h2>
      <ul>
        <li>
          <strong>Eligibility</strong>: To register for Luddy Buddy, you must provide accurate and complete 
          information. Children under 13 must obtain parental consent to register.
        </li>
        <li>
          <strong>Account Security</strong>: You are responsible for maintaining the confidentiality of your 
          account credentials. Please notify us immediately of any unauthorized access or breach.
        </li>
      </ul>

      <h2>3. Services Provided</h2>
      <p>
        Luddy Buddy provides online classes, including 1:1 and small group sessions for kids, covering 
        software courses and school subjects. Course materials are exclusively for personal, educational 
        use and may not be shared or redistributed.
      </p>

      <h2>4. User Conduct</h2>
      <p>You agree not to:</p>
      <ul>
        <li>Share content that is offensive, abusive, or infringes on the rights of others.</li>
        <li>Use the App for any unlawful activities.</li>
        <li>Misuse the platform by accessing or disrupting Luddy Buddy’s servers.</li>
      </ul>

      <h2>5. Payments and Refunds</h2>
      <p>
        Courses may be subject to fees, and payment is due at the time of registration. Refund policies 
        will be outlined separately and are subject to our discretion.
      </p>

      <h2>6. Intellectual Property</h2>
      <p>
        All content on Luddy Buddy, including course materials, videos, graphics, and other resources, are 
        owned by Luddy Buddy or our licensors and are protected by copyright laws. Unauthorized use or 
        distribution is prohibited.
      </p>

      <h2>7. Limitation of Liability</h2>
      <p>
        Luddy Buddy is not liable for any direct or indirect damages arising from the use of our App or 
        services, including but not limited to damages from interruptions, data loss, or unauthorized access 
        to your account.
      </p>

      <h2>8. Termination of Use</h2>
      <p>
        Luddy Buddy reserves the right to suspend or terminate your account if you violate these Terms of 
        Use or if we deem it necessary to protect our users, platform, or services.
      </p>

      <h2>9. Modifications to Terms</h2>
      <p>
        We reserve the right to update these Terms of Use at any time. Continued use of Luddy Buddy after 
        changes are posted constitutes your acceptance of the new terms.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have questions about these Terms of Use, please contact us at:
      </p>
      <p>
        <strong>Email</strong>: info@luddybuddy.com <br />
        <strong>Contact Number</strong>: +1 (718) 872-8472
      </p>
    </div>
  );
}

export default ServiceTerms;
