import React, { useEffect, useState } from "react";
import { CircularProgress, Typography, Box, Card, CardContent, CardMedia, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ApiServices from "../../../Utils/ApiServices/ApiServices";

const EnrolledCourses = () => {
  const { email: paramEmail } = useParams(); // Get email from route parameters
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userType, setUserType] = useState(""); // State to store user type
  const navigate = useNavigate(); // useNavigate hook for navigation

  useEffect(() => {
    // Fetch user data from sessionStorage
    const userDetails = sessionStorage.getItem("LoginUserDetails");
    if (userDetails) {
      const user = JSON.parse(userDetails);
      setUserType(user.user_type);

      const fetchCoursesByAuthor = async () => {
        try {
          const response = await ApiServices.get(`/api/user/${user.id}/courses/`);
          setCourses(response.data.data);
          setLoading(false);
        } catch (err) {
          setError("Failed to load courses.");
          setLoading(false);
        }
      };

      fetchCoursesByAuthor();
    }
  }, [paramEmail]);

  const handleTitleClick = (id, title) => {
    // Navigate based on user type
    if (userType === "student") {
      navigate(`/student/course-details/${id}/${title}`);
    } else if (userType === "teacher") {
      navigate(`/teacher/course-details/${id}/${title}`);
    } else if (userType === "admin") {
      navigate(`/admin-user/course-details/${id}/${title}`);
    } else {
      navigate(`/course-detail/${id}/${title}`);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ flexGrow: 1, padding: "20px" }}>
      <Grid container spacing={3}>
        {courses.map((enrollment) => {
          const course = enrollment.course;
          return (
            <Grid item xs={12} sm={6} md={4} key={course.id}>
              <Card>
                {course.image && (
                  <CardMedia
                    component="img"
                    height="200"
                    image={course.image}
                    alt={course.title}
                    sx={{ objectFit: "cover" }} // Ensure image fits the card
                  />
                )}
                <CardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleTitleClick(course.id, course.title)}
                  >
                    {course.title}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {course.subtitle}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    paragraph
                    sx={{ maxHeight: "100px", overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {course.intro.length > 200 ? `${course.intro.substring(0, 200)}...` : course.intro}
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    <strong>Course Type:</strong> {course.course_type}
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    <strong>Duration:</strong> {course.duration}
                  </Typography>
                  {course.charges && (
                    <Typography variant="body2" color="textPrimary">
                      <strong>Charges:</strong> {course.charges}
                    </Typography>
                  )}
                  <Typography variant="body2" color="textPrimary">
                    <strong>Joining Date:</strong> {enrollment.joining_date}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default EnrolledCourses;
