import React, { useEffect } from 'react';
import { Box, Typography, Button, Container, Grid, Card, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import image1 from "../../../Assets/student/image1.jpg";
import image2 from "../../../Assets/social-media/image2.jpg";
import image3 from "../../../Assets/success/image3.jpg";
import AOS from 'aos';
import 'aos/dist/aos.css';  // Import AOS styles

const AboutUsPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in ms
      easing: 'ease-in-out',
      once: true,     // Animation happens only once while scrolling
    });
  }, []);

  return (
    <Box>
      <Container maxWidth="lg" sx={{ padding: '40px 0', fontFamily: 'Arial, sans-serif' }}>
        {/* Heading Section */}
        <Box sx={{ textAlign: 'center', marginBottom: '40px', color: '#00308f' }}>
          <Typography 
            variant="h3" 
            gutterBottom 
            sx={{ fontWeight: 'bold' }} 
            data-aos="fade-up"
          >
            About Us
          </Typography>
          <Typography 
            variant="subtitle1" 
            sx={{ color: 'text.secondary' }} 
            data-aos="fade-up"
          >
            Discover the fun and engaging world of learning at Luddy Buddy!
          </Typography>
        </Box>

        {/* First Section: Text Left, Image Right */}
        <Grid 
          container 
          spacing={4} 
          justifyContent="center" 
          alignItems="center" 
          sx={{ marginTop: '30px' }}
        >
          <Grid item xs={10} md={6} data-aos="fade-right">
            <Typography variant="body1" sx={{ fontSize: '1.2rem', lineHeight: '1.8' }}>
              Welcome to <strong>Luddy Buddy</strong>, the ultimate learning destination where curiosity meets creativity! 
              At Luddy Buddy, we believe that learning should be fun, engaging, and accessible to everyone, whether you're diving into the world of coding or exploring other exciting subjects.
            </Typography>
          </Grid>
          <Grid item xs={10} md={6} data-aos="fade-left">
            <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
              <CardMedia
                component="img"
                image={image1}
                alt="Learning"
                sx={{ borderRadius: 2, objectFit: "cover" }}
              />
            </Card>
          </Grid>
        </Grid>

        {/* Second Section: Image Left, Text Right */}
        <Grid 
          container 
          spacing={4} 
          justifyContent="center" 
          alignItems="center" 
          sx={{ marginTop: '30px', flexDirection: { xs: 'column', md: 'row-reverse' } }}
        >
          <Grid item xs={10} md={6} data-aos="fade-left">
            <Typography variant="body1" sx={{ fontSize: '1.2rem', lineHeight: '1.8' }}>
              Our platform offers a variety of courses, ranging from <strong>HTML, CSS, and JavaScript</strong> to subjects like <strong>Math, Science, and more</strong>. Each course is thoughtfully designed to cater to students of all ages, making it easy to learn new skills in an interactive and practical way.
            </Typography>
          </Grid>
          <Grid item xs={10} md={6} data-aos="fade-right">
            <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
              <CardMedia
                component="img"
                image={image2}
                alt="Courses"
                sx={{ borderRadius: 2, objectFit: "cover" }}
              />
            </Card>
          </Grid>
        </Grid>

        {/* Third Section: Text Left, Image Right */}
        <Grid 
          container 
          spacing={4} 
          justifyContent="center" 
          alignItems="center" 
          sx={{ marginTop: '30px' }}
        >
          <Grid item xs={10} md={6} data-aos="fade-right">
            <Typography variant="body1" sx={{ fontSize: '1.2rem', lineHeight: '1.8' }}>
              Whether you're a beginner starting your educational journey or an advanced learner looking to sharpen your skills, Luddy Buddy is here to support your goals with expert instructors, engaging content, and a friendly learning environment.
            </Typography>
          </Grid>
          <Grid item xs={10} md={6} data-aos="fade-left">
            <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
              <CardMedia
                component="img"
                image={image3}
                alt="Success"
                sx={{ borderRadius: 2, objectFit: "cover" }}
              />
            </Card>
          </Grid>
        </Grid>

        {/* Call to Action Button */}
        <Box 
          sx={{ textAlign: 'center', marginTop: '40px', marginBottom: '20px' }} 
        
        >
          <Button variant="contained" color="primary" size="large" style={{ backgroundColor:'#ff6600'}}>
            <Link
              to="/student-sign-up"
              style={{ textDecoration: "none", color: "white" }}
            >
              Join Us Today
            </Link>
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutUsPage;
