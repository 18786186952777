import axios from "axios";

// Get the base URL from the environment variable
const BASE_URL = process.env.REACT_APP_SERVERURL;

class ApiService {
  get = async (url = '') => {
    try {
      const response = await axios.get(`${BASE_URL}${url}`);
      return { error: null, data: response };
    } catch (error) {
      console.error("Error fetching data:", error);
      return { error, data: null };
    }
  };

  post = async (url, payload = {}, headers = {}) => {
    try {
      const response = await axios.post(
        `${BASE_URL}${url}`,
        payload,
        { headers }
      );
      return { error: null, data: response.data };
    } catch (error) {
      console.log("Post Request Error:", error);
      throw error;
    }
  };

  delete = async (url, headers = {}) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}${url}`,
        { headers }
      );
      return { error: null, data: response.data };
    } catch (error) {
      console.log("Delete Request Error:", error);
      throw error;
    }
  };

  put = async (url, payload = {}, headers = {}) => {
    try {
      const response = await axios.put(
        `${BASE_URL}${url}`,
        payload,
        { headers }
      );
      return { error: null, data: response.data };
    } catch (error) {
      console.log("Error:", error);
      return { error, data: null };
    }
  };

  patch = async (url, payload = {}, headers = {}) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}${url}`,
        payload,
        { headers }
      );
      return { error: null, data: response.data };
    } catch (error) {
      console.log("Error:", error);
      return { error, data: null };
    }
  };
}

export default new ApiService();
