import React from 'react';
import { Navigate } from 'react-router-dom';

const AdminPrivateRoute = ({ element }) => {
  // Check for user data in sessionStorage
  const userData = JSON.parse(sessionStorage.getItem('LoginUserDetails'));

  // Verify if user data exists and if the user_type is 'student'
  if (userData && userData.user_type === 'admin') {
    return element;
  }

  // If not authenticated or not a student, redirect to the login page or another appropriate page
  return <Navigate to="/sign-in" />;
};

export default AdminPrivateRoute;
