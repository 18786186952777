import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, IconButton
} from '@mui/material';
import { Visibility } from '@mui/icons-material';
import CreateExercise from './CreateExercise';
import ApiServices from '../../../Utils/ApiServices/ApiServices';

const ExercisesList = () => {
  const { id, title } = useParams(); // Getting course id and title from params
  const navigate = useNavigate(); // Use navigate for routing
  const [exercises, setExercises] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);

  // Function to fetch exercises by course id
  const fetchExercises = async () => {
    try {
      const response = await ApiServices.get(`/api/courses/${id}/exercises/`);
      setExercises(response.data.data);
    } catch (error) {
      console.error('Error fetching exercises:', error);
    }
  };

  // Fetch exercises on component mount
  useEffect(() => {
    fetchExercises();
  }, []);

  // Callback to refresh the exercise list after creating a new exercise
  const handleExerciseCreated = () => {
    setShowCreateForm(false); // Hide the create form
    fetchExercises(); // Fetch the updated list of exercises
  };

  // Function to handle row click
  const handleRowClick = (exerciseId) => {
    const userDetails = JSON.parse(sessionStorage.getItem('LoginUserDetails'));
    
    if (userDetails) {
      const { user_type } = userDetails;
      switch (user_type) {
        case 'teacher':
          navigate(`/teacher/course-exercises-details/${exerciseId}/${title}/${id}`);
          break;
        case 'student':
          navigate(`/student/course-exercises-details/${exerciseId}/${title}/${id}`);
          break;
        case 'admin':
          navigate(`/admin/user/course-exercises-details/${exerciseId}/${title}/${id}`);
          break;
        default:
          console.error('Unknown user type:', user_type);
      }
    } else {
      console.error('User not logged in or user details not found in session storage.');
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Exercises for {title}
      </Typography>

      {/* Button to toggle showing the CreateExercise form */}
      <Button variant="contained" color="primary" onClick={() => setShowCreateForm(!showCreateForm)}>
        {showCreateForm ? 'Hide Form' : 'Create New Exercise'}
      </Button>

      {/* Conditionally show the CreateExercise form */}
      {showCreateForm && (
        <CreateExercise courseId={id} onExerciseCreated={handleExerciseCreated} />
      )}

      <TableContainer component={Paper} sx={{ marginTop: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>File</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exercises.map((exercise) => (
              <TableRow key={exercise.id} onClick={() => handleRowClick(exercise.id)} style={{ cursor: 'pointer' }}>
                <TableCell>{exercise.title}</TableCell>
                <TableCell>{exercise.description}</TableCell>
                <TableCell>{new Date(exercise.due_date).toLocaleDateString()}</TableCell>
                <TableCell>
                  {exercise.file ? (
                    <Button variant="outlined"
                      component="a"
                      href={exercise.file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Visibility />
                      Preview
                    </Button>
                  ) : (
                    <Button variant="outlined" disabled>
                      <Visibility /> No File
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ExercisesList;
