import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { format, isToday, isPast } from 'date-fns';
import ApiServices from '../../../Utils/ApiServices/ApiServices';

const AllExercises = () => {
  const [exercises, setExercises] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = sessionStorage.getItem('LoginUserDetails');
    const userId = userData ? JSON.parse(userData).id : null;

    if (userId) {
      ApiServices.get(`/api/students/${userId}/exercises/`)
        .then((response) => {
          // Sort exercises by creation date in descending order
          const sortedExercises = response.data.data.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          setExercises(sortedExercises);
        })
        .catch((error) => {
          console.error('Error fetching exercises:', error);
        });
    }
  }, []);

  const handleNavigate = (exercise) => {
    navigate(`/student/course-exercises-details/${exercise.id}/${exercise.title}/${exercise.course}`);
  };

  return (
    <div style={styles.exercisesContainer}>
      <Typography variant="h4" style={styles.title}>Your Fun Assignments!</Typography>
      {exercises.length > 0 ? (
        <div style={styles.cardsGrid}>
          {exercises.map((exercise) => {
            const isPastDue = isPast(new Date(exercise.due_date)) && !isToday(new Date(exercise.due_date));
            const isDueToday = isToday(new Date(exercise.due_date));

            return (
              <div 
                key={exercise.id} 
                style={{
                  ...styles.exerciseCard,
                  ...(isDueToday && styles.dueTodayCard),
                }}
                onMouseEnter={() => document.getElementById(`icon-${exercise.id}`).classList.add('open')}
                onMouseLeave={() => document.getElementById(`icon-${exercise.id}`).classList.remove('open')}
                onClick={() => handleNavigate(exercise)}
              >
                <div style={styles.cardContent}>
                  <div id={`icon-${exercise.id}`} style={styles.folderContainer}>
                    <FolderIcon style={styles.folderIcon} />
                  </div>
                  <Typography variant="h6" style={styles.exerciseTitle}>
                    {exercise.title}
                  </Typography>
                  <div style={styles.dateContainer}>
                    <Typography style={styles.dateLabel}>
                      Assigned Date: {format(new Date(exercise.created_at), 'dd/MM/yyyy')}
                    </Typography>
                    <Typography
                      style={{
                        ...styles.dateLabel,
                        ...(isPastDue && styles.pastDueDate),
                        ...(isDueToday && styles.blinkingDate),
                      }}
                    >
                      Last Submission Date: {format(new Date(exercise.due_date), 'dd/MM/yyyy')}
                    </Typography>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <Typography variant="h6" style={styles.noExercisesMessage}>
          🎉 No exercises assigned yet! Enjoy your free time! 🎉
        </Typography>
      )}
    </div>
  );
};

const styles = {
  exercisesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#fffbf2',
    minHeight: '100vh',
  },
  title: {
    color: '#FF6F61',
    marginBottom: '20px',
    fontSize: '2.5rem',
    fontFamily: 'Comic Sans MS, sans-serif',
  },
  cardsGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    justifyContent: 'center',
    width: '100%',
  },
  exerciseCard: {
    width: '200px',
    height: '250px',
    background: 'linear-gradient(135deg, #FFF176, #FFAB91)',
    borderRadius: '15px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '15px',
    cursor: 'pointer',
    transition: 'transform 0.3s ease-in-out, background-color 0.3s',
    textAlign: 'center',
  },
  dueTodayCard: {
    animation: 'blink 1s step-end infinite alternate',
    animationTimingFunction: 'ease-in-out',
    background: 'linear-gradient(135deg, #FFCCCB, #FF6F61)',
  },
  '@keyframes blink': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0.5 },
    '100%': { opacity: 1 },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  exerciseTitle: {
    color: '#333',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginTop: '10px',
    fontFamily: 'Arial, sans-serif',
  },
  folderContainer: {
    fontSize: '3rem',
    transition: 'transform 0.3s, color 0.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  folderIcon: {
    fontSize: '4rem',
    color: '#8BC34A',
    transition: 'transform 0.3s ease-in-out',
  },
  folderIconOpen: {
    transform: 'rotate(-20deg)',
    color: '#FF6F61',
  },
  dateContainer: {
    marginTop: '8px',
  },
  dateLabel: {
    color: '#444',
    fontSize: '0.9rem',
    fontFamily: 'Comic Sans MS, sans-serif',
  },
  pastDueDate: {
    color: 'red',
  },
  blinkingDate: {
    animation: 'blinkText 1s ease-in-out infinite',
  },
  '@keyframes blinkText': {
    '0%, 100%': { color: 'red' },
    '50%': { color: 'black' },
  },
  noExercisesMessage: {
    color: '#FF6F61',
    fontSize: '1.8rem',
    fontFamily: 'Comic Sans MS, sans-serif',
    textAlign: 'center',
    marginTop: '30px',
  },
};

export default AllExercises;
