import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TextField, Select, MenuItem, InputLabel, FormControl, Checkbox, Snackbar, Alert,
  Box
} from '@mui/material';
import ApiServices from '../../../Utils/ApiServices/ApiServices';

const AllEnquiries = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [filteredEnquiries, setFilteredEnquiries] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [contactedFilter, setContactedFilter] = useState(''); // New filter state for Contacted status
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');

  // Fetch enquiries data from the API
  const fetchEnquiries = () => {
    ApiServices.get('/api/enquiries/')
      .then(response => {
        const sortedEnquiries = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort by new entries
        setEnquiries(sortedEnquiries);
        setFilteredEnquiries(sortedEnquiries);
      })
      .catch(error => {
        console.error('Error fetching enquiries:', error);
      });
  };

  useEffect(() => {
    fetchEnquiries();
  }, []);

  // Filter function for search, course filter, and contacted filter
  useEffect(() => {
    let filteredData = enquiries;

    // Search filter for first name, last name, or email
    if (searchQuery) {
      filteredData = filteredData.filter(enquiry =>
        enquiry.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        enquiry.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        enquiry.email.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Dropdown filter for course title
    if (selectedCourse) {
      filteredData = filteredData.filter(enquiry => enquiry.course_title === selectedCourse);
    }

    // Filter by contacted status
    if (contactedFilter !== '') {
      filteredData = filteredData.filter(enquiry => enquiry.contacted === (contactedFilter === 'contacted'));
    }

    setFilteredEnquiries(filteredData);
  }, [searchQuery, selectedCourse, contactedFilter, enquiries]);

  // Function to update contacted status
  const handleContactedChange = (enquiryId, newStatus) => {
    ApiServices.patch(`/api/enquiries/update/${enquiryId}/`, { contacted: newStatus })
      .then(() => {
        setAlertType('success');
        setAlertMessage('Contacted status updated successfully!');
        setSnackbarOpen(true);
        fetchEnquiries();  // Fetch updated data after the patch request
      })
      .catch(() => {
        setAlertType('error');
        setAlertMessage('Failed to update contacted status.');
        setSnackbarOpen(true);
      });
  };

  // Get unique course titles for the dropdown
  const courseTitles = [...new Set(enquiries.map(enquiry => enquiry.course_title))];

  return (
    <div style={{ padding: '20px' }}>
      {/* Search Input */}
      <TextField
        label="Search by First Name, Last Name or Email"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: '20px' }}
      />

      {/* Dropdown to Filter by Course Title */}
      <Box display="flex" justifyContent="space-between" alignItems="center" gap="5px" mb={2} mt={2} sx={{  margin: 'auto',marginTop:2 }}>

      <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px' }}  sx={{ flexGrow: 1 }}>
        <InputLabel>Filter by Course Title</InputLabel>
        <Select
          value={selectedCourse}
          onChange={(e) => setSelectedCourse(e.target.value)}
          label="Filter by Course Title"
        >
          <MenuItem value="">
            <em>All Courses</em>
          </MenuItem>
          {courseTitles.map((courseTitle) => (
            <MenuItem key={courseTitle} value={courseTitle}>
              {courseTitle}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Dropdown to Filter by Contacted Status */}
      <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px' }}>
        <InputLabel>Filter by Contacted Status</InputLabel>
        <Select
          value={contactedFilter}
          onChange={(e) => setContactedFilter(e.target.value)}
          label="Filter by Contacted Status"
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          <MenuItem value="contacted">Contacted</MenuItem>
          <MenuItem value="not_contacted">Not Contacted</MenuItem>
        </Select>
      </FormControl>
      </Box>

      {/* Table to Display Enquiries */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Course Title</TableCell>
              <TableCell>Contacted</TableCell>
              <TableCell>Contacted Date</TableCell>
              <TableCell>Enquiry Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEnquiries.map((enquiry) => (
              <TableRow key={enquiry.id}>
                <TableCell>{enquiry.id}</TableCell>
                <TableCell>{enquiry.first_name}</TableCell>
                <TableCell>{enquiry.last_name}</TableCell>
                <TableCell>{enquiry.email}</TableCell>
                <TableCell>{enquiry.mobile}</TableCell>
                <TableCell>{enquiry.address}</TableCell>
                <TableCell>{enquiry.course_title}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={enquiry.contacted}
                    onChange={(e) => handleContactedChange(enquiry.id, e.target.checked)}
                    disabled={enquiry.contacted === true}
                  />
                </TableCell>
                <TableCell>
                  {enquiry.contacted_date ? new Date(enquiry.contacted_date).toLocaleString() : 'Not contacted yet'}
                </TableCell>
                <TableCell>{new Date(enquiry.created_at).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Snackbar for showing success and error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={alertType} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AllEnquiries;
