import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,
  TextField, Box, IconButton
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';
import ApiServices from '../../../Utils/ApiServices/ApiServices';


const AllSentMails = () => {
  const [emails, setEmails] = useState([]);
  const [filteredEmails, setFilteredEmails] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch emails from the API
  useEffect(() => {
    ApiServices.get('/api/sent-emails/')
      .then(response => {
        setEmails(response.data.data);
        setFilteredEmails(response.data.data); // Set the initial filtered list
      })
      .catch(error => {
        console.error('There was an error fetching the emails!', error);
      });
  }, []);

  // Filter emails based on search term
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = emails.filter(email =>
      email.subject.toLowerCase().includes(value)
    );
    setFilteredEmails(filtered);
  };

  // Open the dialog with selected email details
  const handleRowClick = (email) => {
    setSelectedEmail(email);
    setOpenDialog(true);
  };

  // Close the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEmail(null);
  };

  const navigate = useNavigate()
  // Handler for the "Send New Mail" button click
  const handleSendNewMail = () => {
    navigate("/admin-user/send-new-mail")
    // You can implement navigation or show a modal for sending a new mail here
  };

  return (
    <div>
      {/* Search Filter and Send New Mail Button */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={2} sx={{  margin: 'auto',marginTop:2 }}>
        <TextField
          label="Search by Subject"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearch}
          sx={{ flexGrow: 1 }}
        />
        <IconButton  onClick={handleSendNewMail}>
         <Button variant="contained">
            Send New Mail
          <EmailIcon />
         </Button>
        </IconButton>
      </Box>

      {/* Table to display emails */}
      <TableContainer component={Paper} sx={{ margin: 'auto',marginTop:4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>ID</strong></TableCell>
              <TableCell><strong>Subject</strong></TableCell>
              <TableCell><strong>To (Emails)</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEmails.map((email) => (
              <TableRow
                key={email.id}
                hover
                style={{ cursor: 'pointer' }}
                onClick={() => handleRowClick(email)}
              >
                <TableCell>{email.id}</TableCell>
                <TableCell>{email.subject}</TableCell>
                <TableCell>{email.to}</TableCell> {/* Show email IDs in the 'to' field */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog to show email details */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>Email Details</DialogTitle>
        {selectedEmail && (
          <DialogContent>
            <Typography variant="h6" gutterBottom><strong>Subject:</strong> {selectedEmail.subject}</Typography>
            <Typography><strong>To:</strong> {selectedEmail.to}</Typography>
            <Typography><strong>CC:</strong> {selectedEmail.cc}</Typography>
            <Typography><strong>Body:</strong></Typography>
            <Typography sx={{ marginBottom: 2 }}>{selectedEmail.body}</Typography>
            <Typography><strong>Footer:</strong></Typography>
            <Typography>{selectedEmail.footer}</Typography>
            <Typography sx={{ marginTop: 2 }}><strong>Status:</strong> {selectedEmail.status}</Typography>
            {selectedEmail.error_message && (
              <Typography color="error"><strong>Error:</strong> {selectedEmail.error_message}</Typography>
            )}
            <Typography sx={{ marginTop: 2 }}><strong>Sent At:</strong> {new Date(selectedEmail.created_at).toLocaleString()}</Typography>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AllSentMails;
