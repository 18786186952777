import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Snackbar,
  Alert,
  IconButton,
  InputAdornment,
  Grid,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ApiServices from "../../../Utils/ApiServices/ApiServices";

const TeacherRegistration = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    verify_password: "",
    company_email: "",
    is_account_verified: false,
    is_active: false,
    user_type: "teacher",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    phone_number: "",
    password: "",
    verify_password: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Real-time validation
    switch (name) {
      case "email":
        validateEmail(value);
        break;
      case "phone_number":
        validatePhoneNumber(value);
        break;
      case "password":
        validatePassword(value);
        break;
      case "verify_password":
        validateVerifyPassword(value);
        break;
      default:
        break;
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: emailRegex.test(email) ? "" : "Invalid email address.",
    }));
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    setErrors((prevErrors) => ({
      ...prevErrors,
      phone_number: phoneRegex.test(phoneNumber)
        ? ""
        : "Phone number must be 10 digits.",
    }));
  };

  const validatePassword = (password) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      password:
        password.length >= 6
          ? ""
          : "Password must be at least 6 characters long.",
    }));
  };

  const validateVerifyPassword = (verifyPassword) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      verify_password:
        verifyPassword === formData.password ? "" : "Passwords do not match.",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Final validation check before submitting
    if (
      errors.email ||
      errors.phone_number ||
      errors.password ||
      errors.verify_password
    ) {
      setSnackbarMessage("Please fix the errors before submitting.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await ApiServices.post("/api/register/", formData, {
        "Content-Type": "application/json",
      });

      // const data = await response.data.json();

      if (response.data.message === "User registered successfully") {
        setSnackbarMessage("Registration successful!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate("/sign-in");
        }, 2000);
      } else {
        setSnackbarMessage("Registration failed.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Something went wrong. Please try again later.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          marginTop: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 8,
        }}
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              Welcome to Luddy Buddy Coaching Institute
            </Typography>
            <Typography variant="h6" gutterBottom>
              Join Us as Teacher
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <form onSubmit={handleSubmit}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="first_name"
                    label="First Name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="last_name"
                    label="Last Name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    type="email"
                    error={!!errors.email}
                    helperText={errors.email}
                    disabled={isSubmitting}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="phone_number"
                    label="Phone Number"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    inputProps={{ maxLength: 10 }}
                    error={!!errors.phone_number}
                    helperText={errors.phone_number}
                    disabled={isSubmitting}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={formData.password}
                    onChange={handleChange}
                    error={!!errors.password}
                    helperText={errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword((show) => !show)}
                            edge="end"
                            disabled={isSubmitting}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    disabled={isSubmitting}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="verify_password"
                    label="Verify Password"
                    name="verify_password"
                    type={showPassword ? "text" : "password"}
                    value={formData.verify_password}
                    onChange={handleChange}
                    error={!!errors.verify_password}
                    helperText={errors.verify_password}
                    disabled={isSubmitting}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      backgroundColor: "#48CFCB",
                      ":hover": { backgroundColor: "#05b6b3" },
                    }}
                    disabled={isSubmitting}
                    startIcon={isSubmitting && <CircularProgress size={20} />}
                  >
                    {isSubmitting ? "Registering..." : "Register"}
                  </Button>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TeacherRegistration;
