import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Paper,
  Typography,
  Grid,
  Button,
  Divider,
  CircularProgress,
  Box,
} from "@mui/material";
import ApiServices from "../../../Utils/ApiServices/ApiServices";

const UserDetails = () => {
  const { id } = useParams(); // Get ID from route parameters
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  console.log("useruser", user);
  // Fetch user data based on ID
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await ApiServices.get(`/api/users/${id}/`);
        setUser(response.data.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    return (
      <Typography variant="h6" color="error" align="center">
        User not found
      </Typography>
    );
  }

  const initials = `${user.first_name[0].toUpperCase()}${user.last_name[0].toUpperCase()}`;
  const handleCourseClick = (id, email) => {
    navigate(`/admin-user/teacher-all-courses/${id}/${email}`);
  };
  const handleBatchClick = (id, email) => {
    navigate(`/admin-user/teacher-all-batches/${id}/${email}`);
  };
  const handleAccessClick = (id, email,type) => {
    navigate(`/admin-user/user-access/${id}/${email}`);
  };
  return (
    <Paper
      elevation={3}
      style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}
    >
      {/* Display Initials */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        marginBottom="20px"
      >
        <Typography
          variant="h2"
          component="div"
          style={{
            backgroundColor: "#05b6b3",
            color: "white",
            borderRadius: "50%",
            width: "100px",
            height: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "36px",
            fontWeight: "bold",
            margin: "auto",
          }}
        >
          {initials}
        </Typography>
        <Typography variant="h5" style={{ marginTop: "10px" }}>
          {user.first_name} {user.last_name}
        </Typography>
      </Box>

      <Divider style={{ margin: "20px 0" }} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">First Name:</Typography>
          <Typography>{user.first_name}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Last Name:</Typography>
          <Typography>{user.last_name}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Email:</Typography>
          <Typography>{user.email}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Phone Number:</Typography>
          <Typography>{user.phone_number}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Account Verified:</Typography>
          <Typography>{user.is_account_verified ? "Yes" : "No"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Email Verified:</Typography>
          <Typography>{user.is_email_verified ? "Yes" : "No"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Active:</Typography>
          <Typography>{user.is_active ? "Yes" : "No"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">User Type:</Typography>
          <Typography>{user.user_type}</Typography>
        </Grid>
      </Grid>

      <Divider style={{ margin: "20px 0" }} />

      {/* Conditional Buttons for Teacher User Type */}
      <Box display="flex" justifyContent="center" marginTop="20px">
        {user.user_type === "teacher" && (
          <>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: "10px" }}
              onClick={() => handleCourseClick(user.id, user.email)}
            >
              Show Courses
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleBatchClick(user.id, user.email)}
              style={{ marginRight: "10px" }}
            >
              Show Batches
            </Button>
            {user.user_type !== "student" &&
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAccessClick(user.id, user.email,user.user_type)}
            >
              Show Access
            </Button>}
          </>
        )}
      </Box>
    </Paper>
  );
};

export default UserDetails;
