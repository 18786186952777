import { useMediaQuery } from '@mui/material';
import React from 'react'
import { Route, Routes } from 'react-router-dom';
import CreateCourse from '../../Components/TeacherSection/TeacherCourses/CreateCourse';
import TeacherAllCourses from '../../Components/TeacherSection/TeacherCourses/TeacherAllCourses';
import TeacherPrivateRoute from '../PrivateRoutes/TeacherPrivateRoute';
import TeacherSidebar from '../../Container/TeacherSidebar/TeacherSidebar';
import CourseDetails from '../../Components/TeacherSection/TeacherCourses/CourseDetails';
import CoursePurchasedStudents from '../../Components/TeacherSection/CoursePurchasedStudents/CoursePurchasedStudents';
import ListOfTeacherBatches from '../../Components/TeacherSection/TeacherBatches/ListOfTeacherBatches';
import CreateTeacherBatches from '../../Components/TeacherSection/TeacherBatches/CreateTeacherBatches';
import StudentsInBatch from '../../Components/TeacherSection/TeacherBatches/StudentsInBatch';
import LectureSessionsList from '../../Components/TeacherSection/LectureSessions/LectureSessionsList';
import SessionDetails from '../../Components/TeacherSection/LectureSessions/SessionDetails';
import ExercisesList from '../../Components/TeacherSection/Exercises/ExercisesList';
import ExerciseDetails from '../../Components/TeacherSection/Exercises/ExerciseDetails';

const TeacherRoutes = () => {
  const isLargeScreen = useMediaQuery("(min-width:1441px)");

  return (
    <div>
      <TeacherSidebar/>
      <div
        style={{
          marginLeft: isLargeScreen ? "250px" : "0",
          flexGrow: 1,
          padding: "10px",
        }}
      >
        <Routes>
          <Route
            path="/teacher-create-courses"
            element={<TeacherPrivateRoute element={<CreateCourse />} />}
          />
          <Route
            path="/teacher-all-courses"
            element={<TeacherPrivateRoute element={<TeacherAllCourses />} />}
          />
          <Route
            path="/course-details/:id/:title"
            element={<TeacherPrivateRoute element={<CourseDetails />} />}
          />
          <Route
            path="/course-enrolled-students/:id/:title"
            element={<TeacherPrivateRoute element={<CoursePurchasedStudents />} />}
          />
          <Route
            path="/course-exercises/:id/:title"
            element={<TeacherPrivateRoute element={<ExercisesList />} />}
          />
          <Route
            path="/course-exercises-details/:id/:title/:courseID"
            element={<TeacherPrivateRoute element={<ExerciseDetails />} />}
          />
          <Route
            path="/all-avalable-batches"
            element={<TeacherPrivateRoute element={<ListOfTeacherBatches />} />}
          />
          <Route
            path="/create-new-batch"
            element={<TeacherPrivateRoute element={<CreateTeacherBatches />} />}
          />
           <Route
            path="/students-in-batch/:id/:title"
            element={<TeacherPrivateRoute element={<StudentsInBatch />} />}
          />
           <Route
            path="/sessions-of-batch/:id/:title"
            element={<TeacherPrivateRoute element={<LectureSessionsList />} />}
          />
           <Route
            path="/sessions-details/:id/:title/:sessionId"
            element={<TeacherPrivateRoute element={<SessionDetails />} />}
          />
        </Routes>
      </div>
    </div>
  )
}

export default TeacherRoutes