import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Box, Button, Container } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import Logo from "../../../Assets/cosmos  Logo.png";
import expert from "../../../Assets/online-class-illustration-concept/expert.jpg";
import flexible from "../../../Assets/hand-drawn-multitask-business-woman/flexible.jpg";
import Certificates from "../../../Assets/online-certification-concept/Certificates.jpg";
import TeacherList from '../../TeacherSection/TeacherList/TeacherList';

function HomePage() {
  useEffect(() => {
    AOS.init({
      duration: 1500, 
      once: true, 
    });
  }, []);

  return (
    <div>
      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#f5f5f5', padding: '100px 0', textAlign: 'center' }}>
        <Container maxWidth="lg">
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={6} data-aos="fade-in">
              <img src={Logo} alt="Learning Image" style={{ maxWidth: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={6} data-aos="fade-up">
              <Typography variant="h3" sx={{ marginBottom: '20px' }}>
                Learn Anything, Anytime, Anywhere
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '30px' }}>
                Explore thousands of courses to improve your skills, grow your career, and enrich your life.
              </Typography>
              <Link to="/our-courses" style={{ textDecoration: 'none' }}>
                <Button variant="contained" color="primary" size="large">
                  Get Started
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Features Section */}
      <Box sx={{ padding: '50px 40px', textAlign: 'center' }}>
        <Typography variant="h2" sx={{ fontSize: '2.5rem', marginBottom: '30px' }} data-aos="fade-up">
          Why Choose Us?
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
              data-aos="fade-up" data-aos-delay="200"
            >
              <Box component="img" src={expert} alt="Expert Instructors" sx={{ width: '100%', height: 'auto', marginBottom: '20px' }} />
              <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                Expert Instructors
              </Typography>
              <Typography variant="body2">
                Learn from top instructors with years of experience in their fields.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
              data-aos="fade-up" data-aos-delay="400"
            >
              <Box component="img" src={flexible} alt="Flexible Learning" sx={{ width: '100%', height: 'auto', marginBottom: '20px' }} />
              <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                Flexible Learning
              </Typography>
              <Typography variant="body2">
                Access your courses at any time, from anywhere in the world.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
              data-aos="fade-up" data-aos-delay="600"
            >
              <Box component="img" src={Certificates} alt="Certificates of Completion" sx={{ width: '100%', height: 'auto', marginBottom: '20px' }} />
              <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                Certificates of Completion
              </Typography>
              <Typography variant="body2">
                Receive a certificate to showcase your newly acquired skills.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Popular Courses Section */}
      <Box sx={{ padding: '50px 40px', textAlign: 'center' }}>
        <Typography variant="h2" sx={{ fontSize: '2.5rem', marginBottom: '30px' }} data-aos="fade-up"data-aos-delay="700">
          Popular Courses
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          {/* Course 1 */}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
              data-aos="fade-up" data-aos-delay="200"
            >
              <Box component="img" src="course1.jpg" alt="Introduction to Python" sx={{ width: '100%', height: 'auto', marginBottom: '20px', borderRadius: '10px' }} />
              <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                Introduction to Python
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: '20px' }}>
                Learn Python programming from scratch.
              </Typography>
              <Button variant="contained" color="warning" size="large">
                Enroll Now
              </Button>
            </Box>
          </Grid>

          {/* Course 2 */}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
              data-aos="fade-up" data-aos-delay="400"
            >
              <Box component="img" src="course2.jpg" alt="Web Development" sx={{ width: '100%', height: 'auto', marginBottom: '20px', borderRadius: '10px' }} />
              <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                Web Development
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: '20px' }}>
                Master HTML, CSS, and JavaScript for web development.
              </Typography>
              <Button variant="contained" color="warning" size="large">
                Enroll Now
              </Button>
            </Box>
          </Grid>

          {/* Course 3 */}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
              data-aos="fade-up" data-aos-delay="600"
            >
              <Box component="img" src="course3.jpg" alt="Data Science with R" sx={{ width: '100%', height: 'auto', marginBottom: '20px', borderRadius: '10px' }} />
              <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                Data Science with R
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: '20px' }}>
                Learn data science techniques and tools using R.
              </Typography>
              <Button variant="contained" color="warning" size="large">
                Enroll Now
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
{/* Teachers */}
<Box sx={{ padding: '50px 40px', textAlign: 'center' }}>
<Typography variant="h2" sx={{ fontSize: '2.5rem', marginBottom: '30px' }} data-aos="fade-up">
      Experienced teachers
        </Typography>
        <Box data-aos="fade-up">
            <TeacherList/>
        </Box>
</Box>
      {/* Testimonials Section */}
      <Box sx={{ backgroundColor: '#f5f5f5', padding: '50px 0', textAlign: 'center' }}>
        <Typography variant="h2" sx={{ fontSize: '2.5rem', marginBottom: '30px' }} data-aos="fade-up">
          What Our Students Say
        </Typography>

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Box
              sx={{ padding: '20px', backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
              data-aos="fade-up" data-aos-delay="200"
            >
              <Typography variant="body1" sx={{ marginBottom: '20px' }}>
                "The Python course was amazing! I learned so much, and the instructors were incredibly supportive."
              </Typography>
              <Typography variant="subtitle1" sx={{ fontStyle: 'italic' }}>
                - Jane Doe
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{ padding: '20px', backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
              data-aos="fade-up" data-aos-delay="400"
            >
              <Typography variant="body1" sx={{ marginBottom: '20px' }}>
                "I loved the flexibility of learning at my own pace. The courses are well-structured and engaging."
              </Typography>
              <Typography variant="subtitle1" sx={{ fontStyle: 'italic' }}>
                - John Smith
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default HomePage;
