import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Paper,
  CircularProgress,
} from '@mui/material';
import ApiServices from '../../../Utils/ApiServices/ApiServices';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const StudentBatches = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Get email from session storage
    const email = JSON.parse(sessionStorage.getItem('LoginUserDetails'))?.email;

    if (email) {
      // Fetch data using Axios
      ApiServices
        .get(`/api/batches/student/${email}/`)
        .then((response) => {
          setBatches(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message || 'Error fetching data');
          setLoading(false);
        });
    } else {
      setError('Email not found in session data');
      setLoading(false);
    }
  }, []);

  const handleOpenMeetingLink = (link) => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  const handleRowClick = (id, title) => {
    navigate(`/student/sessions-of-batch/${id}/${title}`); // Navigate to the new route
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Batch ID</TableCell>
            <TableCell>Batch Name</TableCell>
            <TableCell>Meeting Link</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>Expected End Date</TableCell>
            <TableCell>Teacher</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {batches.map((batch) => (
            <TableRow
              key={batch.id}
              onClick={() => handleRowClick(batch.batch.batch_id, batch.batch.batch_name)} // Handle row click
              sx={{ cursor: 'pointer' }} // Optional: Change cursor style to pointer
            >
              <TableCell>{batch.batch.batch_id}</TableCell>
              <TableCell>{batch.batch.batch_name}</TableCell>
              <TableCell>
                {batch.batch.meeting_link ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click when button is clicked
                      handleOpenMeetingLink(batch.batch.meeting_link);
                    }}
                  >
                    Open Meeting Link
                  </Button>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    Meeting not scheduled yet
                  </Typography>
                )}
              </TableCell>
              <TableCell>{batch.batch.start_date}</TableCell>
              <TableCell>{batch.batch.expected_end_date}</TableCell>
              <TableCell>
                {`${batch.batch.teacher.first_name} ${batch.batch.teacher.last_name}`}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StudentBatches;
