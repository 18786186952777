import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, Avatar } from '@mui/material';
import axios from 'axios';
import ApiServices from '../../../Utils/ApiServices/ApiServices';

const TeacherList = () => {
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    ApiServices.get('/api/teachers/')
      .then((response) => {
        setTeachers(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching teacher data:', error);
      });
  }, []);

  return (
    <div style={{ padding: '20px' , display:'flex' , justifyContent:'center'
     }}>
      <Grid container spacing={3}>
        {teachers.map((teacher) => (
          <Grid item xs={12} sm={6} md={3} key={teacher.id}>
            <Card>
              <CardContent style={{ textAlign: 'center' }}>
               
                <Avatar style={{ margin: 'auto', width: 100, height: 100 }}>
                  {teacher.first_name.charAt(0)}{teacher.last_name.charAt(0)}
                </Avatar>

                <Typography variant="h6" style={{ marginTop: '10px' }}>
                  {teacher.first_name} {teacher.last_name}
                </Typography>


                <Typography variant="body2" color="textSecondary">
                  {/* Example qualification and subject - customize as per your needs */}
                  Qualification: Bachelor's in Education
                </Typography>

                <Typography variant="body2" color="textSecondary">
                  Subject: Mathematics
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default TeacherList;
