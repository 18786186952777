import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import { Visibility } from '@mui/icons-material';
import ApiServices from '../../../Utils/ApiServices/ApiServices';

const ExerciseDetails = () => {
  const { id, courseID } = useParams(); // Getting exercise ID and course ID from params
  const [exercise, setExercise] = useState(null);
  const [students, setStudents] = useState([]);
  const [assignedStudents, setAssignedStudents] = useState([]); // New state for assigned students
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [processing, setProcessing] = useState(false);

  // Function to fetch exercise details by ID
  const fetchExerciseDetails = async () => {
    try {
      const response = await ApiServices.get(`/api/exercises/${id}/`);
      setExercise(response.data.data);
    } catch (error) {
      console.error('Error fetching exercise details:', error);
      setError('Failed to fetch exercise details');
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch students enrolled in the course
  const fetchEnrolledStudents = async () => {
    try {
      const response = await ApiServices.get(`/api/course/${courseID}/users/`);
      setStudents(response.data.data);
    } catch (error) {
      console.error('Error fetching enrolled students:', error);
      setError('Failed to fetch enrolled students');
    }
  };

  // Function to fetch already assigned students
  const fetchAssignedStudents = async () => {
    try {
      const response = await ApiServices.get(`/api/exercises/${id}/students/`);
      setAssignedStudents(response.data.data.map(student => student.student_id)); 
    } catch (error) {
      console.error('Error fetching assigned students:', error);
      setError('Failed to fetch assigned students');
    }
  };

  // Function to assign exercise to student
  const assignExerciseToStudent = async (studentId) => {
    setProcessing(true);
    try {
      await ApiServices.post('/api/assign-student-to-exercise/', {
        student_id: studentId,
        exercise_id: id,
      });
      setSnackbarMessage('Exercise assigned successfully!');
      setSnackbarSeverity('success');
      setAssignedStudents(prev => [...prev, studentId]); // Update assigned students state
    } catch (error) {
      setSnackbarMessage('Failed to assign exercise.');
      setSnackbarSeverity('error');
    } finally {
      setProcessing(false);
      setSnackbarOpen(true);
    }
  };

  // Fetch exercise details, enrolled students, and assigned students on component mount
  useEffect(() => {
    fetchExerciseDetails();
    fetchEnrolledStudents();
    fetchAssignedStudents(); // Fetch assigned students here
  }, [id, courseID]);

  // Determine the text color based on due date
  const getDueDateStyle = (dueDate) => {
    const currentDate = new Date();
    const dueDateObj = new Date(dueDate);
    
    if (dueDateObj < currentDate) {
      return { color: 'red' }; // Past due date
    } else if (dueDateObj.toDateString() === currentDate.toDateString()) {
      return { color: 'yellow' }; // Current due date
    } else {
      return { color: 'green' }; // Future due date
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <Box sx={{ overflowX: 'auto' }}> {/* Added overflow to prevent table from going out of screen */}
      {/* Exercise Details Card */}
      <Card sx={{ margin: 2, backgroundColor: 'white' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {exercise.title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {exercise.description}
          </Typography>
          <Typography variant="body2" gutterBottom sx={getDueDateStyle(exercise.due_date)}>
            Due Date: {new Date(exercise.due_date).toLocaleDateString()}
          </Typography>
          {exercise.file ? (
            <Button 
              variant="outlined" 
              component="a" 
              href={exercise.file} 
              target="_blank" 
              rel="noopener noreferrer" 
              startIcon={<Visibility />}
            >
              Preview
            </Button>
          ) : (
            <Button variant="outlined" disabled>
              <Visibility /> No File
            </Button>
          )}
        </CardContent>
      </Card>

      {/* Enrolled Students Table */}
      {students.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Student ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Joining Date</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>Amount Paid</TableCell>
                <TableCell>Actions</TableCell> {/* New Actions column */}
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((student) => (
                <TableRow key={student.id}>
                  <TableCell>{student.student.id}</TableCell>
                  <TableCell>{`${student.student.first_name} ${student.student.last_name}`}</TableCell>
                  <TableCell>{student.student.email}</TableCell>
                  <TableCell>{new Date(student.joining_date).toLocaleDateString()}</TableCell>
                  <TableCell>{student.is_paid ? 'Paid' : 'Free'}</TableCell>
                  <TableCell>{student.is_paid ? student.amount_paid : 'N/A'}</TableCell>
                  <TableCell>
                    <Button 
                      variant="contained" 
                      onClick={() => assignExerciseToStudent(student.student.id)} 
                      disabled={assignedStudents.includes(student.student.id) || processing} // Disable button if already assigned
                    >
                      {assignedStudents.includes(student.student.id) ? 'Already Assigned' : processing ? 'Processing...' : 'Assign'}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" sx={{ margin: 2 }}>
          No students available to assign exercise.
        </Typography>
      )}

      {/* Snackbar for success/error messages */}
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={6000} 
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ExerciseDetails;
