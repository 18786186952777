import React, { useState } from 'react';
import { TextField, Button, Snackbar, Alert, IconButton, Box, Typography } from '@mui/material';
import axios from 'axios';
import { Add, Delete } from '@mui/icons-material';
import ApiServices from '../../../Utils/ApiServices/ApiServices';

const CreateCourse = () => {
  const LoginUser = sessionStorage.getItem("LoginUserDetails");
  const LoginUserDetails = JSON.parse(LoginUser);

  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    intro: '',
    author: LoginUserDetails.email,
    course_type: 'free',
    course_charges: '0',
    duration: '2 weeks',
    image: null,
    brochure: null,
    description: [{ content: '', objectives: '' }]
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (acceptedFiles, type) => {
    setFormData({ ...formData, [type]: acceptedFiles[0] });
  };

  const handleDescriptionChange = (index, field, value) => {
    const newDescriptions = formData.description.map((desc, i) =>
      i === index ? { ...desc, [field]: value } : desc
    );
    setFormData({ ...formData, description: newDescriptions });
  };

  const addDescription = () => {
    setFormData({
      ...formData,
      description: [...formData.description, { content: '', objectives: '' }]
    });
  };

  const removeDescription = (index) => {
    setFormData({
      ...formData,
      description: formData.description.filter((_, i) => i !== index)
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.title || !formData.subtitle || !formData.intro || !formData.author) {
      setSnackbarMessage('Please fill in all required fields.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const data = new FormData();
    data.append('title', formData.title);
    data.append('subtitle', formData.subtitle);
    data.append('intro', formData.intro);
    data.append('author', formData.author);
    data.append('course_type', formData.course_type);
    data.append('course_charges', formData.course_charges);
    data.append('duration', formData.duration);
    data.append('description', JSON.stringify(formData.description));
    if (formData.image) data.append('image', formData.image);
    if (formData.brochure) data.append('brochure', formData.brochure);

    try {
      const response = await ApiServices.post('/api/courses/', data);
      setSnackbarMessage('Course created successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      // Clear all fields after successful submission
      setFormData({
        title: '',
        subtitle: '',
        intro: '',
        author: LoginUserDetails.email,
        course_type: 'free',
        course_charges: '0',
        duration: '2 weeks',
        image: null,
        brochure: null,
        description: [{ content: '', objectives: '' }]
      });

    } catch (error) {
      setSnackbarMessage('Error creating course. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Title"
          name="title"
          fullWidth
          margin="normal"
          value={formData.title}
          onChange={handleChange}
          required
        />
        <TextField
          label="Subtitle"
          name="subtitle"
          fullWidth
          margin="normal"
          value={formData.subtitle}
          onChange={handleChange}
          required
        />
        <TextField
          label="Intro"
          name="intro"
          fullWidth
          margin="normal"
          value={formData.intro}
          onChange={handleChange}
          required
        />
        <TextField
          label="Course Type"
          name="course_type"
          select
          fullWidth
          margin="normal"
          value={formData.course_type}
          onChange={handleChange}
          SelectProps={{ native: true }}
        >
          <option value="free">Free</option>
          <option value="paid">Paid</option>
        </TextField>
        <TextField
          label="Course Charges"
          name="course_charges"
          fullWidth
          margin="normal"
          value={formData.course_charges}
          onChange={handleChange}
        />
        <TextField
          label="Duration"
          name="duration"
          fullWidth
          margin="normal"
          value={formData.duration}
          onChange={handleChange}
        />

        {formData.description.map((desc, index) => (
          <Box key={index} sx={{ marginBottom: 2 }}>
            <TextField
              label={`Description Content ${index + 1}`}
              fullWidth
              margin="normal"
              value={desc.content}
              onChange={(e) => handleDescriptionChange(index, 'content', e.target.value)}
              required
            />
            <TextField
              label={`Description Objectives ${index + 1}`}
              fullWidth
              margin="normal"
              value={desc.objectives}
              onChange={(e) => handleDescriptionChange(index, 'objectives', e.target.value)}
              required
            />
            <IconButton onClick={() => removeDescription(index)} color="error">
              <Delete />
            </IconButton>
          </Box>
        ))}
        <Button variant="contained" color="primary" onClick={addDescription} startIcon={<Add />}>
          Add Description
        </Button>

        <Box mt={3}>
          <Typography variant="h6">Upload Course Image</Typography>
          <Button variant="contained" component="label" style={{ marginRight: '10px' }}>
            Upload Image
            <input
              type="file"
              hidden
              onChange={(e) => handleFileChange(e.target.files, 'image')}
            />
          </Button>
        </Box>

        <Box mt={3}>
          <Typography variant="h6">Upload Course Brochure</Typography>
          <Button variant="contained" component="label">
            Upload Brochure
            <input
              type="file"
              hidden
              onChange={(e) => handleFileChange(e.target.files, 'brochure')}
            />
          </Button>
        </Box>

        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
          Submit
        </Button>
      </form>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CreateCourse;
