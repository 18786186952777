import React, { useState, useEffect } from 'react';
import { Button, Typography, TextField, Box, Grid, useMediaQuery } from '@mui/material';

const sentences = {
  english: {
    low: ["Typing fast is a useful skill."],
    medium: ["Mastering algorithms can improve coding skills significantly."],
    high: ["React components help in building reusable UIs."]
  },
  hindi: {
    low: ["स्पीड रीडिंग टेस्टमध्ये आपले स्वागत आहे!"],
    medium: ["वेगवान वाचनाचा सराव सुरू करण्यासाठी येथे तुमचा मजकूर प्रविष्ट करा."],
    high: ["शब्द गती समायोजित करा."]
  },
  punjabi: {
    low: ["Luddy_Buddy ਸਪੀਡ ਰੀਡਿੰਗ ਟੈਸਟ ਵਿੱਚ ਤੁਹਾਡਾ ਸੁਆਗਤ ਹੈ!"],
    medium: ["ਸਪੀਡ ਰੀਡਿੰਗ ਦਾ ਅਭਿਆਸ ਸ਼ੁਰੂ ਕਰਨ ਲਈ ਇੱਥੇ ਆਪਣਾ ਟੈਕਸਟ ਦਰਜ ਕਰੋ।"],
    high: ["ਤੁਹਾਡੀ ਪੜ੍ਹਨ ਦੀ ਗਤੀ ਦੇ ਅਨੁਸਾਰ ਸਪੀਡ ਨੂੰ ਵਿਵਸਥਿਤ ਕਰੋ।"]
  }
};

const TypingTest = () => {
  const [language, setLanguage] = useState('english');
  const [level, setLevel] = useState('low');
  const [sentence, setSentence] = useState('');
  const [userInput, setUserInput] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [wpm, setWpm] = useState(0);
  const [accuracy, setAccuracy] = useState(100);
  const [mistakes, setMistakes] = useState(0);
  const [isFinished, setIsFinished] = useState(false);

  const isMobile = useMediaQuery('(max-width:600px)'); // Check if the device is mobile

  const generateSentence = () => {
    const sentencesForLevel = sentences[language][level];
    const randomSentence = sentencesForLevel[Math.floor(Math.random() * sentencesForLevel.length)];
    setSentence(randomSentence);
    setUserInput('');
    setStartTime(null);
    setWpm(0);
    setAccuracy(100);
    setMistakes(0);
    setIsFinished(false);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setUserInput(value);

    if (!startTime) {
      setStartTime(Date.now());
    }

    let mistakeCount = 0;
    for (let i = 0; i < value.length; i++) {
      if (value[i] !== sentence[i]) {
        mistakeCount++;
      }
    }
    setMistakes(mistakeCount);

    const totalTyped = value.length;
    const correctChars = totalTyped - mistakeCount;
    const newAccuracy = Math.max((correctChars / totalTyped) * 100, 0).toFixed(2);
    setAccuracy(newAccuracy);

    if (value === sentence) {
      const timeTaken = (Date.now() - startTime) / 1000 / 60;
      const wordsTyped = sentence.split(' ').length;
      setWpm(Math.round(wordsTyped / timeTaken));
      setIsFinished(true);
    }
  };

  const renderHighlightedSentence = () => {
    return sentence.split('').map((char, index) => {
      let style = {};

      if (index < userInput.length) {
        style = { color: userInput[index] === char ? 'green' : 'red' };
      } else if (index === userInput.length) {
        style = { color: 'blue', textDecoration: 'underline' };
      }

      return (
        <span key={index} style={style}>
          {char}
        </span>
      );
    });
  };

  const handleReset = () => {
    generateSentence();
  };

  const handleLevelChange = (level) => {
    setLevel(level);
    generateSentence();
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    generateSentence();
  };

  useEffect(() => {
    generateSentence();
  }, [language, level]);

  const VirtualKeyboard = () => {
    const rows = [
      ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
      ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P','{','}'],
      ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L',';','"'],
      ['Z', 'X', 'C', 'V', 'B', 'N', 'M', ',', '.','?']
    ];

    const nextChar = sentence[userInput.length]?.toUpperCase() || '';

    const renderKey = (key) => {
      const isNext = nextChar === key;

      return (
        <Button
          key={key}
          variant="outlined"
          sx={{
            margin: '1px',
            backgroundColor: isNext ? 'blue' : 'transparent',
            color: isNext ? 'white' : 'default',
            width: '40px',
          }}
        >
          {key}
        </Button>
      );
    };

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 2 }}>
        {rows.map((row, rowIndex) => (
          <Box key={rowIndex} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            {row.map(renderKey)}
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box sx={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', color: '#00308f' }}>
        Speed Typing Test
      </Typography>

      {/* Grid layout for buttons and stats */}
      <Grid container spacing={2} sx={{ marginBottom: 2, marginTop: '40px' }}>
        <Grid item xs={12} md={6}>
          {/* Language buttons */}
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Button variant={language === 'english' ? 'contained' : 'outlined'} onClick={() => handleLanguageChange('english')}>
              English
            </Button>
            <Button variant={language === 'hindi' ? 'contained' : 'outlined'} onClick={() => handleLanguageChange('hindi')}>
              Hindi
            </Button>
            <Button variant={language === 'punjabi' ? 'contained' : 'outlined'} onClick={() => handleLanguageChange('punjabi')}>
              Punjabi
            </Button>
          </Box>

          {/* Difficulty level buttons */}
          <Box sx={{ display: 'flex', gap: '10px', marginTop: 2 }}>
            <Button variant={level === 'low' ? 'contained' : 'outlined'} onClick={() => handleLevelChange('low')}>
              Low
            </Button>
            <Button variant={level === 'medium' ? 'contained' : 'outlined'} onClick={() => handleLevelChange('medium')}>
              Medium
            </Button>
            <Button variant={level === 'high' ? 'contained' : 'outlined'} onClick={() => handleLevelChange('high')}>
              High
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          {/* Performance stats */}
          <Typography><strong>Words per minute (WPM):</strong> {wpm}</Typography>
          <Typography><strong>Accuracy:</strong> {accuracy}%</Typography>
          <Typography><strong>Mistakes:</strong> {mistakes}</Typography>
        </Grid>
      </Grid>

      <Typography variant="h5">
        <strong>Sentence: </strong>
        <Typography sx={{ fontSize: '35px' }}>
          {renderHighlightedSentence()}
        </Typography>
      </Typography>

      <TextField
        multiline
        rows={3}
        variant="outlined"
        placeholder="Start typing here..."
        value={userInput}
        onChange={handleChange}
        disabled={isFinished}
        fullWidth
        sx={{ marginTop: 2, marginBottom: 2 }}
      />

      {/* Virtual Keyboard: Only show for desktop and when language is English */}
      {!isMobile && language === 'english' && <VirtualKeyboard />}

      {/* Buttons */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleReset}
        sx={{ marginTop: 2, marginBottom: 5 }}
      >
        Restart
      </Button>
    </Box>
  );
};

export default TypingTest;
