import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Checkbox, Select, MenuItem, TextField, Snackbar, Alert, Paper, Button
} from '@mui/material';
import AddReadingContent from './AddReadingContent';
import ApiServices from '../../../Utils/ApiServices/ApiServices';

const ReadingContentList = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTitle, setSearchTitle] = useState('');
  const [filterLevel, setFilterLevel] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isAddingContentVisible, setIsAddingContentVisible] = useState(false); // State for dropdown visibility

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await ApiServices.get('/api/speedreading/data/');
      setData(response.data.data);
      setFilteredData(response.data.data);
      setIsAddingContentVisible(false)
    } catch (error) {
      console.error("Error fetching data:", error);
      setSnackbar({ open: true, message: 'Error fetching data', severity: 'error' });
    }
  };

  const handleUpdate = async (id, field, value) => {
    try {
      await ApiServices.patch(`/api/speedreading/data/${id}/`, { [field]: value });
      setSnackbar({
        open: true,
        message: `Successfully updated ${field} for "${data.find(item => item.id === id).title}"`,
        severity: 'success'
      });
      fetchData(); // Fetch updated data after successful update
    } catch (error) {
      console.error("Error updating data:", error);
      setSnackbar({ open: true, message: `Error updating ${field}`, severity: 'error' });
    }
  };

  const handleSearch = (e) => {
    setSearchTitle(e.target.value);
    filterData(e.target.value, filterLevel);
  };

  const handleLevelFilter = (e) => {
    setFilterLevel(e.target.value);
    filterData(searchTitle, e.target.value);
  };

  const filterData = (title, level) => {
    const filtered = data.filter(item =>
      item.title.toLowerCase().includes(title.toLowerCase()) &&
      (level ? item.level === level : true)
    );
    setFilteredData(filtered);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  const toggleAddContent = () => {
    setIsAddingContentVisible(!isAddingContentVisible); // Toggle visibility
  };

  return (
    <div>
      {/* Toggle Button for AddReadingContent */}
      <Button
        variant="outlined"
        color="primary"
        onClick={toggleAddContent}
        style={{ marginBottom: '20px' }}
      >
        {isAddingContentVisible ? 'Hide Add Reading Content Form' : 'Add Reading Content'}
      </Button>

      {/* Conditionally render AddReadingContent based on visibility state */}
      {isAddingContentVisible && <AddReadingContent onAddSuccess={fetchData} />}

      <h2>Reading Content List</h2>
      
      {/* Search Filter */}
      <TextField
        label="Search by Title"
        variant="outlined"
        value={searchTitle}
        onChange={handleSearch}
        style={{ marginRight: '10px' }}
      />
      
      {/* Level Filter */}
      <Select
        value={filterLevel}
        onChange={handleLevelFilter}
        displayEmpty
        variant="outlined"
      >
        <MenuItem value="">All Levels</MenuItem>
        <MenuItem value="easy">Easy</MenuItem>
        <MenuItem value="medium">Medium</MenuItem>
        <MenuItem value="hard">Hard</MenuItem>
      </Select>

      {/* Data Table */}
      <TableContainer component={Paper} style={{ backgroundColor: 'white', marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Content</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Level</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.title}</TableCell>
                <TableCell>{item.content}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={item.active}
                    onChange={() => handleUpdate(item.id, 'active', !item.active)}
                  />
                </TableCell>
                <TableCell>
                  <Select
                    value={item.level}
                    onChange={(e) => handleUpdate(item.id, 'level', e.target.value)}
                  >
                    <MenuItem value="easy">Easy</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="hard">Hard</MenuItem>
                  </Select>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ReadingContentList;
