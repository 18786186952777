import React, { useEffect, useState } from "react";
import { CircularProgress, Typography, Box, Card, CardContent, CardMedia, Grid, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ApiServices from "../../../Utils/ApiServices/ApiServices";

const TeacherAllCourses = () => {
  const { email: paramEmail } = useParams(); // Get email from route parameters
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userType, setUserType] = useState(""); // State to store user type
  const navigate = useNavigate(); // useNavigate hook for navigation

  useEffect(() => {
    // Fetch user data from sessionStorage
    const userDetails = sessionStorage.getItem("LoginUserDetails");

    if (!userDetails) {
      setError("User details not found.");
      setLoading(false);
      return;
    }

    const user = JSON.parse(userDetails);
    const teacherEmail = paramEmail || user.email;
    setUserType(user.user_type); // Set user type from session storage

    const fetchCoursesByAuthor = async () => {
      try {
        const response = await ApiServices.get(`/api/courses/author/${teacherEmail}/`);
        setCourses(response.data.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load courses.");
        setLoading(false);
      }
    };

    fetchCoursesByAuthor();
  }, [paramEmail]);

  const handleTitleClick = (id, title) => {
    // Navigate based on user type
    if (userType === "student") {
      navigate(`/student/course-details/${id}/${title}`);
    } else if (userType === "teacher") {
      navigate(`/teacher/course-details/${id}/${title}`);
    } else if (userType === "admin") {
      navigate(`/admin-user/course-details/${id}/${title}`); 
    } else {
      navigate(`/default/course-details/${id}/${title}`);
    }
  };

  const showEnrolledStudents = (id, title) => {
    if (userType === "teacher") {
      navigate(`/teacher/course-enrolled-students/${id}/${title}`);
    } else if (userType === "admin") {
      navigate(`/admin-user/course-enrolled-students/${id}/${title}`);
    }
  };

  const showExercises = (id, title) => {
    if (userType === "teacher") {
      navigate(`/teacher/course-exercises/${id}/${title}`);
    } else if (userType === "admin") {
      navigate(`/admin-user/course-exercises/${id}/${title}`);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ flexGrow: 1, padding: "20px" }}>
      <Grid container spacing={3}>
        {courses.map((course) => (
          <Grid item xs={12} sm={6} md={4} key={course.id}>
            <Card>
              {course.image && (
                <CardMedia
                  component="img"
                  height="200"
                  image={course.image}
                  alt={course.title}
                  sx={{ objectFit: "cover" }} // Ensure image fits the card
                />
              )}
              <CardContent>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleTitleClick(course.id, course.title)}
                >
                  {course.title}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {course.subtitle}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  paragraph
                  sx={{ maxHeight: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {course.intro.length > 200 ? `${course.intro.substring(0, 200)}...` : course.intro}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  <strong>Course Type:</strong> {course.course_type}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  <strong>Duration:</strong> {course.duration}
                </Typography>
                {course.charges && (
                  <Typography variant="body2" color="textPrimary">
                    <strong>Charges:</strong> {course.charges}
                  </Typography>
                )}

                {userType !== "student"  &&
                <>
                <div style={{display:"flex",justifyContent:"space-between",flexWrap:"wrap",gap:2,marginTop:"10px"}}>
                  
                  <Button
                    variant="contained"
                    color="secondary"
                   
                    onClick={() =>
                      showEnrolledStudents(course.id, course.title)
                    }
                  >
                    Enrolled Students
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                 

                    onClick={() =>
                      showExercises(course.id, course.title)
                    }
                    
                  >
                   Exercises
                  </Button>
                  </div>
                </>}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TeacherAllCourses;
