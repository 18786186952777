import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Snackbar,
  Alert,
  IconButton,
  InputAdornment,
  Card,
  CardContent,
  Link,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import ApiServices from "../../../Utils/ApiServices/ApiServices";

const SignInPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Real-time validation
    if (name === "email") {
      validateEmail(value);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: emailRegex.test(email) ? "" : "Invalid email address.",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (errors.email || !formData.email || !formData.password) {
      setSnackbarMessage("Please fill all fields correctly.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    setIsLoading(true);

    try {
      const response = await ApiServices.post("/api/login/", formData, {
        "Content-Type": "application/json",
      });
      const data = response.data.user;

      if (response.data.user) {
        const { id, first_name, last_name, email, phone_number, user_type } =
          response.data.user;

        const userData = {
          id,
          first_name,
          last_name,
          email,
          phone_number,
          user_type,
        };
        console.log("userDatauserData", userData);
        // const secretKey = process.env.REACT_APP_USER_ENC_DEC_KEY;
        // const encryptedUserData = CryptoJS.AES.encrypt(JSON.stringify(userData), secretKey).toString();

        sessionStorage.setItem("LoginUserDetails", JSON.stringify(userData));

        setSnackbarMessage("Login successful!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        setTimeout(() => {
          let redirectPath;
          switch (userData.user_type) {
            case "student":
              redirectPath = "/student/available-courses";
              break;
            case "teacher":
              redirectPath = "/teacher/teacher-all-courses";
              break;
            case "admin":
              redirectPath = "/admin-user/all-available-users";
              break;
            case "manager":
              redirectPath = "/manager";
              break;
            default:
              redirectPath = "/";
          }
          navigate(redirectPath);
        }, 2000);
      } else {
        setSnackbarMessage(data.message || "Login failed.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Something went wrong. Please try again later.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLoginSuccess = async (response) => {
  
    try {
      const res = await ApiServices.post("/api/google-login/", {
        token: response.credential,
      });
      const data = res.data.user;

      if (res.data.user) {
        const { id, first_name, last_name, email, phone_number, user_type } =
        res.data.user;

        const userData = {
          id,
          first_name,
          last_name,
          email,
          phone_number,
          user_type,
        };

        sessionStorage.setItem("LoginUserDetails", JSON.stringify(userData));

        setSnackbarMessage("Login successful!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        setTimeout(() => {
          let redirectPath;
          switch (userData.user_type) {
            case "student":
              redirectPath = "/student/available-courses";
              break;
            case "teacher":
              redirectPath = "/teacher/teacher-all-courses";
              break;
            case "admin":
              redirectPath = "/admin-user/all-available-users";
              break;
            case "manager":
              redirectPath = "/manager";
              break;
            default:
              redirectPath = "/";
          }
          navigate(redirectPath);
        }, 2000);
      } else {
        setSnackbarMessage(data.message || "Login failed.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Something went wrong. Please try again later.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLoginError = () => {
    console.error("Google Login Failed");
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card sx={{ width: "100%", padding: 2 }}>
          <CardContent>
            <Typography variant="h4" gutterBottom align="center">
              Luddy Buddy Coaching Institute
            </Typography>
            <Typography variant="h6" gutterBottom align="center">
              "Unlock Your Potential!"
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                type="email"
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={formData.password}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((show) => !show)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: "#48CFCB",
                    ":hover": { backgroundColor: "#05b6b3" },
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  ) : (
                    "Login"
                  )}
                </Button>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
              <Link href="/student-sign-up" variant="body2" sx={{ textAlign: 'left' }}>
                  Don't have an account? Sign Up
                </Link>
                </Box>
            </form>

            <GoogleOAuthProvider clientId="214266046101-hhm2qmmh0f5kipblm388r7au9mg0qvjt.apps.googleusercontent.com">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <GoogleLogin
                  onSuccess={handleGoogleLoginSuccess}
                  onError={handleGoogleLoginError}
                />
              </div>
            </GoogleOAuthProvider>
          </CardContent>
        </Card>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SignInPage;
