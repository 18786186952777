import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Grid, Typography, IconButton, Stack } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

const WebFooter = () => {
  return (
    <Box sx={{ backgroundColor: '#05b6b3', color: '#ffffff', py: 4, mt: 8 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* About Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Luddy Buddy
            </Typography>
            <Typography variant="body2">
              Luddy Buddy is a coaching institute dedicated to teaching kids software development courses as well as all school subjects.
            </Typography>
          </Grid>

          {/* Links Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Quick Links
            </Typography>
            <Stack spacing={1}>
              <Link to="/about-us" style={{ color: 'inherit', textDecoration: 'none' }}>About</Link>
              <Link to="/contact-us" style={{ color: 'inherit', textDecoration: 'none' }}>Contact</Link>
              <Link to="/our-courses" style={{ color: 'inherit', textDecoration: 'none' }}>Courses</Link>
              <Link to="/sign-in" style={{ color: 'inherit', textDecoration: 'none' }}>Sign In</Link>
              <Link to="/student-sign-up" style={{ color: 'inherit', textDecoration: 'none' }}>Sign Up</Link>
              <Link to="/privacy-policy" style={{ color: 'inherit', textDecoration: 'none' }}>Privacy Policy</Link>
              <Link to="/service-terms" style={{ color: 'inherit', textDecoration: 'none' }}>Terms of Use</Link>
            </Stack>
          </Grid>

          {/* Contact and Socials */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Contact Us
            </Typography>
            <Typography variant="body2">
              Email: <a href="mailto:info@luddybuddy.com" style={{ color: '#ffffff', textDecoration: 'none' }}>info@luddybuddy.com</a>
            </Typography>
            <Box sx={{ mt: 2 }}>
              <IconButton component="a" href="https://facebook.com" target="_blank" sx={{ color: '#ffffff' }}>
                <FacebookIcon />
              </IconButton>
              <IconButton component="a" href="https://instagram.com" target="_blank" sx={{ color: '#ffffff' }}>
                <InstagramIcon />
              </IconButton>
              <IconButton component="a" href="https://linkedin.com" target="_blank" sx={{ color: '#ffffff' }}>
                <LinkedInIcon />
              </IconButton>
              <IconButton component="a" href="https://twitter.com" target="_blank" sx={{ color: '#ffffff' }}>
                <TwitterIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        {/* Copyright */}
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="body2">
            © {new Date().getFullYear()} Luddy Buddy. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default WebFooter;
