import React, { useEffect, useState } from 'react';
import { TextField, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, MenuItem, Select, FormControl,
  InputLabel, Button, Snackbar, Alert } from '@mui/material';
import ApiServices from '../../../Utils/ApiServices/ApiServices';
import { useParams } from 'react-router-dom';

const CoursePurchasedStudents = () => {
  const [students, setStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterPaidStatus, setFilterPaidStatus] = useState('all');
  const [batches, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState({});
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', 
    severity: '' });
  const params = useParams();

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await ApiServices.get(
          `/api/course/${params.id}/users/`);
        setStudents(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching students:", error);
        setLoading(false);
      }
    };

    const fetchBatches = async () => {
      const LoginUserdata = JSON.parse(sessionStorage.getItem('LoginUserDetails'));
      try {
        const response = await ApiServices.get(
          `/api/batches/by-email/${LoginUserdata.email}/`);
        setBatches(response.data.data);
      } catch (error) {
        console.error("Error fetching batches:", error);
      }
    };

    fetchStudents();
    fetchBatches();
  }, [params.id]);

  const handleBatchSelection = (studentId, batchId) => {
    setSelectedBatch((prev) => ({ ...prev, [studentId]: batchId }));
  };

  const handleAssignBatch = async (studentId) => {
    if (selectedBatch[studentId]) {
      try {
        await ApiServices.post('/api/assign-students-to-batch/', {
          batch_id: selectedBatch[studentId],
          assigned_course_ids: [studentId],
        });
        setSnackbar({
          open: true,
          message: 'Student successfully assigned to batch',
          severity: 'success',
        });
      } catch (error) {
        console.error('Error assigning students to batch:', error);
        setSnackbar({
          open: true,
          message: 'Error assigning students',
          severity: 'error',
        });
      }
    } else {
      setSnackbar({
        open: true,
        message: 'Please select a batch',
        severity: 'warning',
      });
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredStudents = students.filter((student) => {
    const fullName = `${student.student.first_name} ${student.student
      .last_name}`.toLowerCase();
    const email = student.student.email.toLowerCase();
    const matchesSearch = fullName.includes(searchQuery.toLowerCase()) ||
      email.includes(searchQuery.toLowerCase());
    const matchesPaidStatus = filterPaidStatus === 'all' ||
      (filterPaidStatus === 'paid' && student.is_paid) ||
      (filterPaidStatus === 'not_paid' && !student.is_paid);
    return matchesSearch && matchesPaidStatus;
  });

  if (loading) return <p>Loading...</p>;

  return (
    <div style={{ padding: '20px' }}>
      <TextField
        label="Search by Name or Email"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={handleSearch}
        style={{ marginBottom: '20px' }}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Paid Status</TableCell>
              <TableCell>Select Batch</TableCell>
              <TableCell>Assign</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredStudents.map((student) => (
              <TableRow key={student.id}>
                <TableCell>{student.student.first_name}</TableCell>
                <TableCell>{student.student.last_name}</TableCell>
                <TableCell>{student.student.email}</TableCell>
                <TableCell>{student.student.phone_number}</TableCell>
                <TableCell>{student.is_paid ? 'Paid' : 'Not Paid'}</TableCell>
                <TableCell>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Select Batch</InputLabel>
                    <Select
                      value={selectedBatch[student.id] || ''}
                      onChange={(e) => handleBatchSelection(
                        student.id, e.target.value)}
                      label="Select Batch"
                    >
                      {batches.map((batch) => (
                        <MenuItem key={batch.batch_id} 
                          value={batch.batch_id}>
                          {batch.batch_id} - {batch.batch_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAssignBatch(student.id)}
                  >
                    Assign
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CoursePurchasedStudents;
