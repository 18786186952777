import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  TextField,
  IconButton,
  Input,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  Paper,
  Grid,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ApiServices from "../../../Utils/ApiServices/ApiServices";
import { useNavigate, useParams } from "react-router-dom";
import SubCourseEdit from "./SubCourse/SubCourseEdit";
import { Visibility as VisibilityIcon } from "@mui/icons-material";

const CourseDetails = () => {
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const [subCourseData, setsubCourseData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);
  const [userType, setUserType] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    intro: "",
    course_type: "Paid",
    charges: "",
    duration: "",
    image: null,
    brochure: null,
    description: [{ content: "", objectives: "" }],
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [brochurePreview, setBrochurePreview] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await ApiServices.get(`/api/courses/${id}/`);
        setCourse(response.data.data);
        setsubCourseData(response.data.data.subcourses);
        setFormData(response.data.data);
        setImagePreview(response.data.data.image);
        setBrochurePreview(response.data.data.brochure);

        const loggedInUserData = JSON.parse(
          sessionStorage.getItem("LoginUserDetails")
        );
        setUserType(loggedInUserData.user_type);
        if (
          response.data.data.author.email === loggedInUserData.email ||
          loggedInUserData.user_type === "admin"
        ) {
          setIsAuthor(true);
        }
      } catch (err) {
        setSnackbar({
          open: true,
          message: "Failed to fetch course details",
          severity: "error",
        });
      }
    };

    fetchCourseDetails();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If 'Free' is selected, set charges to 0
    if (name === "course_type" && value === "Free") {
      setFormData((prev) => ({ ...prev, charges: 0, [name]: value }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prev) => ({ ...prev, [name]: files[0] }));
    if (name === "image") {
      setImagePreview(URL.createObjectURL(files[0]));
    } else if (name === "brochure") {
      setBrochurePreview(URL.createObjectURL(files[0]));
    }
  };

  const handleDescriptionChange = (e, index) => {
    const { name, value } = e.target;
    const newDescription = [...formData.description];
    newDescription[index] = { ...newDescription[index], [name]: value };
    setFormData((prev) => ({ ...prev, description: newDescription }));
  };

  const addDescriptionField = () => {
    setFormData((prev) => ({
      ...prev,
      description: [...prev.description, { content: "", objectives: "" }],
    }));
  };

  const removeDescriptionField = (index) => {
    setFormData((prev) => ({
      ...prev,
      description: prev.description.filter((_, i) => i !== index),
    }));
  };

  const handleSave = async () => {
    const updatedData = new FormData();

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== course[key]) {
        if (formData[key] instanceof File) {
          updatedData.append(key, formData[key]);
        } else if (Array.isArray(formData[key])) {
          updatedData.append("description", JSON.stringify(formData[key]));
        } else {
          updatedData.append(key, formData[key]);
        }
      }
    });

    if (
      updatedData.has("title") ||
      updatedData.has("subtitle") ||
      updatedData.has("intro") ||
      updatedData.has("course_type") ||
      updatedData.has("charges") ||
      updatedData.has("duration") ||
      updatedData.has("description") ||
      updatedData.has("image") ||
      updatedData.has("brochure")
    ) {
      try {
        await ApiServices.patch(`/api/courses/${id}/`, updatedData, {
         
            "Content-Type": "multipart/form-data",
          
        });
        setCourse(formData);
        setIsEditing(false);
        setSnackbar({
          open: true,
          message: "Course updated successfully!",
          severity: "success",
        });
      } catch (err) {
        setSnackbar({
          open: true,
          message: "Failed to update course details",
          severity: "error",
        });
      }
    } else {
      console.log("No changes detected.");
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const [subCourses, setsubCourses] = useState(true);
  const [subCoursesEdit, setsubCoursesEdit] = useState(false);

  const handleSubCourseEdit = () => {
    setsubCoursesEdit(true);
  };

  const navigate = useNavigate();
  const showEnrolledStudents = (id, title) => {
    if (userType === "teacher") {
      navigate(`/teacher/course-enrolled-students/${id}/${title}`);
    } else if (userType === "admin") {
      navigate(`/admin-user/course-enrolled-students/${id}/${title}`);
    }
  };

  if (!course) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ padding: "20px" }}>
      {/* <Typography variant="h4" gutterBottom>
        {isEditing ? 'Edit Course' : 'Course Details'}
      </Typography> */}

      <Card sx={{ mb: 3 }}>
        <CardContent>
          {isEditing ? (
            <>
              <Box>
                <TextField
                  name="title"
                  label="Title"
                  value={formData.title}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  name="subtitle"
                  label="Subtitle"
                  value={formData.subtitle}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  name="intro"
                  label="Introduction"
                  value={formData.intro}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel>Course Type</InputLabel>
                  <Select
                    name="course_type"
                    value={formData.course_type}
                    onChange={handleChange}
                  >
                    <MenuItem value="Free">Free</MenuItem>
                    <MenuItem value="Paid">Paid</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  name="charges"
                  label="Charges"
                  value={formData.charges}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  disabled={formData.course_type === "Free"}
                />
                <TextField
                  name="duration"
                  label="Duration"
                  value={formData.duration}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                {/* Image and Brochure Upload */}
                <Typography variant="body1" gutterBottom>
                  Image (Current):
                </Typography>
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{
                      width: "100px",
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  />
                )}
                <Input
                  type="file"
                  name="image"
                  onChange={handleFileChange}
                  sx={{ mb: 2 }}
                />
                <Typography variant="body1" gutterBottom>
                  Brochure (Current):
                </Typography>
                {brochurePreview && (
                  <a
                    href={brochurePreview}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Brochure Preview
                  </a>
                )}
                <Input
                  type="file"
                  name="brochure"
                  onChange={handleFileChange}
                  sx={{ mb: 2 }}
                />
                {/* Description Section */}
                {formData.description.map((desc, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <TextField
                      name="content"
                      label={`Content ${index + 1}`}
                      value={desc.content}
                      onChange={(e) => handleDescriptionChange(e, index)}
                      fullWidth
                      multiline
                      rows={2}
                      margin="normal"
                    />
                    <TextField
                      name="objectives"
                      label={`Objectives ${index + 1}`}
                      value={desc.objectives}
                      onChange={(e) => handleDescriptionChange(e, index)}
                      fullWidth
                      multiline
                      rows={2}
                      margin="normal"
                    />
                    <IconButton
                      color="error"
                      onClick={() => removeDescriptionField(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={addDescriptionField}
                >
                  Add Description
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<SaveIcon />}
                  onClick={handleSave}
                  sx={{ ml: 2 }}
                >
                  Save Changes
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setIsEditing(false)}
                  sx={{ ml: 2 }}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<AddIcon />}
                  sx={{ ml: 2 }}
                  onClick={handleSubCourseEdit}
                >
                  Add SubCourses
                </Button>
              </Box>
              <Box sx={{ mt: 5 }}>
                <SubCourseEdit subCourseData={subCourseData} />
              </Box>
            </>
          ) : (
            <Box>
              <Paper
                elevation={3}
                sx={{ padding: "20px", marginBottom: "20px" }}
              >
                {/* Course Image and Title Section */}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <img
                      src={course.image}
                      alt={course.title}
                      style={{ width: "100%", borderRadius: "10px" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography
                      variant="h4"
                      gutterBottom
                      color="primary"
                      fontWeight="bold"
                    >
                      {course.title}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      {course.subtitle}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {course.intro}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Duration: {course.duration}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Type: {course.course_type}
                    </Typography>
                    {course.charges && (
                      <Typography variant="body2" color="textSecondary">
                        Charges: {course.charges}
                      </Typography>
                    )}
                    <Box sx={{ marginTop: "20px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        href={course.brochure}
                        target="_blank"
                      >
                        Download Brochure
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>

              {/* Author Information */}
              <Paper
                elevation={3}
                sx={{ padding: "20px", marginBottom: "20px" }}
              >
                <Typography variant="h5" gutterBottom>
                  Instructor: {course.author.first_name}{" "}
                  {course.author.last_name}
                </Typography>
                <Typography variant="body1">
                  Email: {course.author.email}
                </Typography>
                <Typography variant="body1">
                  Phone: {course.author.phone_number}
                </Typography>
              </Paper>

              {/* Course Description */}
              <Paper
                elevation={3}
                sx={{ padding: "20px", marginBottom: "20px" }}
              >
                <Typography variant="h5" gutterBottom>
                  Course Description
                </Typography>
                {course.description.map((desc, index) => (
                  <Box key={index} sx={{ marginBottom: "15px" }}>
                    {/* <Typography variant="body1" fontWeight="bold">
                      Section {index + 1}
                    </Typography> */}
                    <Typography variant="body1" gutterBottom>
                      {desc.content}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Objectives: {desc.objectives}
                    </Typography>
                    <Divider sx={{ marginY: "10px" }} />
                  </Box>
                ))}
              </Paper>

              {/* Course Verification Status */}
              {/* <Paper
                elevation={3}
                sx={{ padding: "20px", marginBottom: "20px" }}
              >
                <Typography
                  variant="h6"
                  color={
                    course.is_course_verified ? "success.main" : "error.main"
                  }
                >
                  {course.is_course_verified
                    ? "Course is Verified"
                    : "Course is Not Verified"}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {course.is_course_verified
                    ? "This course is approved and verified."
                    : "This course is pending verification."}
                </Typography>
              </Paper> */}

              {subCourseData !== null && (
  <Paper elevation={3} sx={{ padding: "20px", marginBottom: "20px" }}>
  <Typography variant="h5" gutterBottom>
                  Sub Courses 
                </Typography>
    {subCourseData.map((desc, index) => (
      <Box key={index} sx={{ marginBottom: "15px" }}>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Typography variant="body1" fontWeight="bold">
              {desc.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Objectives: {desc.description}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "right" }}>
            <Button
              variant="text"
              color="primary"
              startIcon={<VisibilityIcon />}
              onClick={() => window.open(desc.file, "_blank")}
            >
              Preview
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ marginY: "10px" }} />
      </Box>
    ))}
  </Paper>
)}

              {isAuthor && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => setIsEditing(true)}
                  >
                    Edit Course
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ ml: 2 }}
                    onClick={() =>
                      showEnrolledStudents(course.id, course.title)
                    }
                  >
                    Show Enrolled Students
                  </Button>
                </>
              )}
            </Box>
          )}
        </CardContent>
      </Card>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CourseDetails;
