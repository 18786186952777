import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1>Privacy Policy for Luddy Buddy</h1>

      <p>
        Luddy Buddy values the privacy of our users  
        and is committed to protecting your personal information. This Privacy Policy outlines how we 
        collect, use, share, and protect your information when you use our online educational platform.
      </p>

      <h2>1. Information We Collect</h2>
      <ul>
        <li>
          <strong>Personal Information</strong>: When you register on Luddy Buddy, we collect information 
          like your name, email address, date of birth, contact details, and payment information.
        </li>
        <li>
          <strong>Usage Information</strong>: We collect data about how you use our App, including session 
          data, activity logs, and information on the courses you attend or view.
        </li>
        <li>
          <strong>Device Information</strong>: We may collect details such as device type, operating system, 
          IP address, and other technical information.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <ul>
        <li><strong>To Provide Services</strong>: Your information is used to set up your account, manage 
          registrations, provide course access, and enable 1:1 or group interactions.
        </li>
        <li><strong>To Communicate</strong>: We may send updates, notifications, reminders, and other 
          essential communications.
        </li>
        <li><strong>To Improve Services</strong>: We analyze user data to understand usage trends and 
          improve the quality of our educational services.
        </li>
      </ul>

      <h2>3. Information Sharing and Disclosure</h2>
      <ul>
        <li><strong>With Instructors</strong>: To facilitate 1:1 or group sessions, your information may be 
          shared with your assigned instructors.
        </li>
        <li><strong>Third-party Service Providers</strong>: We may share data with service providers for 
          payment processing, hosting, or analytics.
        </li>
        <li><strong>Legal Compliance</strong>: We may disclose your information if required by law, legal 
          proceedings, or in response to a request from law enforcement.
        </li>
      </ul>

      <h2>4. Data Security</h2>
      <p>
        We use technical and organizational measures to protect your information from unauthorized access, 
        misuse, or disclosure. Despite our efforts, no security system is impenetrable, and we cannot 
        guarantee complete security.
      </p>

      <h2>5. Your Rights and Choices</h2>
      <p>
        You may request to access, update, or delete your personal information by contacting us. Additionally, 
        you can adjust your notification settings within the App to control certain communications.
      </p>

      <h2>6. Children's Privacy</h2>
      <p>
        Luddy Buddy is designed for children under parental guidance. We encourage parents to review this 
        policy and manage their child's use of our App. Any child under 13 should have their parent’s 
        consent before registering.
      </p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Changes will be posted here, and your continued 
        use of Luddy Buddy signifies acceptance of any updates.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have questions or concerns about our Privacy Policy, please contact us at:
      </p>
      <p>
        <strong>Email</strong>: info@luddybuddy.com <br />
        <strong>Contact Number</strong>: +1 (718) 872-8472
      </p>
    </div>
  );
}

export default PrivacyPolicy;
