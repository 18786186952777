import React, { useEffect, useState } from "react";
import { CircularProgress, Typography, Box, Card, CardContent, CardMedia, Grid, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ApiServices from "../../../Utils/ApiServices/ApiServices";

const CoursesPage = () => {
  const { email: paramEmail } = useParams(); // Get email from route parameters
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userType, setUserType] = useState("");
  const [userId, setUserId] = useState(null); // For logged-in student's ID
  const navigate = useNavigate(); // useNavigate hook for navigation

  useEffect(() => {
    // Fetch user data from sessionStorage
    const userDetails = sessionStorage.getItem("LoginUserDetails");
    if (userDetails) {
      const user = JSON.parse(userDetails);
      setUserType(user.user_type);
      setUserId(user.id); // Assuming the user object contains the student's ID
    }

    const fetchCoursesByAuthor = async () => {
      try {
        const response = await ApiServices.get(`/api/courses-list/`);
        setCourses(response.data.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load courses.");
        setLoading(false);
      }
    };

    fetchCoursesByAuthor();
  }, [paramEmail]);

  const handleTitleClick = (id, title) => {
    const userDetails = sessionStorage.getItem("LoginUserDetails");

    if (!userDetails) {
      // If no user details found, redirect to login page
      navigate(`/course-detail/${id}/${title}`);
      return;
    }

    // If user is logged in, navigate to the course details page based on their user type
    const user = JSON.parse(userDetails);
    if (user.user_type === "student") {
      navigate(`/student/course-details/${id}/${title}`);
    } else if (user.user_type === "teacher") {
      navigate(`/teacher/course-details/${id}/${title}`);
    } else if (user.user_type === "admin") {
      navigate(`/admin-user/course-details/${id}/${title}`);
    } else {
      navigate(`/course-detail/${id}/${title}`);
    }
  };

  const handleBuyCourse = async (courseId) => {
    try {
      const response = await ApiServices.post(`/api/assigned-courses/`, {
        student: userId, // Student ID from session storage
        course: courseId, // Selected course ID
      });
      alert("Course successfully assigned!");
    } catch (err) {
      console.error("Error assigning course:", err);
      alert("Failed to assign course.");
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ flexGrow: 1, padding: "20px" }}>
      <Grid container spacing={3}>
        {courses.map((course) => (
          <Grid item xs={12} sm={6} md={3} key={course.id}> {/* 4 cards per row */}
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}> {/* Consistent card height */}
              {course.image && (
                <CardMedia
                  component="img"
                  height="180" // Set consistent image height
                  image={course.image}
                  alt={course.title}
                  sx={{ objectFit: "cover" }}
                />
              )}
              <CardContent sx={{ flexGrow: 1 }}> {/* Allow content to grow to fill the card */}
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleTitleClick(course.id, course.title)}
                >
                  {course.title}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {course.subtitle}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  paragraph
                  sx={{ maxHeight: '80px', overflow: 'hidden', textOverflow: 'ellipsis' }} // Limit intro text height
                >
                  {course.intro.length > 200 ? `${course.intro.substring(0, 200)}...` : course.intro}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  <strong>Course Type:</strong> {course.course_type}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  <strong>Duration:</strong> {course.duration}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  <strong>Charges:</strong> {course.charges ? course.charges : "Free"}
                </Typography>
                {/* Add the "Course Detail" button */}
                <Button 
                  variant="outlined" 
                  color="secondary" 
                  onClick={() => handleTitleClick(course.id, course.title)}
                  sx={{ marginTop: "10px" }}
                >
                  Course Detail
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CoursesPage;
