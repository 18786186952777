

import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

import Logo from "../../Assets/cosmos  Logo.png";
import { ExitToApp } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  Link as MUILink,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

function SidebarNavbar({ toggleDrawer }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [lowCreditsDialogOpen, setLowCreditsDialogOpen] = useState(false);
  const isLargeScreen = useMediaQuery("(min-width:1440px)");
  const navigate = useNavigate();
  const theme = useTheme();
  const LoginUser = sessionStorage.getItem("LoginUserDetails")
  const LoginUserDetails = JSON.parse(LoginUser)



  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const handleLogoutClick = () => {
    setLogoutDialogOpen(true);
    handleClose();
  };

  const handleLogoutConfirm = () => {
    setIsLoggingOut(true);
    setTimeout(() => {
      sessionStorage.clear();
      setIsLoggingOut(false);
      setLogoutDialogOpen(false);

      navigate("/");
    }, 3000);
  };

  const handleLogoutCancel = () => {
    setLogoutDialogOpen(false);
  };

  

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#48CFCB",
          color: "white",
          borderBottom: "1px solid #2a2a2a",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center">
            {!isLargeScreen && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer("top", true)}
                sx={{ marginRight: 1 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <img
              src={Logo}
              alt="Hospital Logo"
              style={{ maxWidth: "100%", maxHeight: 65 }}
            />
          </Box>
    
          <IconButton
            edge="end"
            color="inherit"
            aria-label="user account"
            onClick={handleMenu}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <Link
                to="/client/user-profile"
                style={{ textDecoration: "none", color: "black" }}
              >
                {LoginUserDetails?.first_name} {" "} {LoginUserDetails?.last_name}
              </Link>
            </MenuItem>
            <MenuItem onClick={handleLogoutClick}>
              <Button startIcon={<ExitToApp />} style={{ color: "red" }}>
                Logout
              </Button>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Dialog
        open={logoutDialogOpen}
        onClose={handleLogoutCancel}
        aria-labelledby="logout-dialog-title"
      >
        <DialogTitle id="logout-dialog-title">Logout Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleLogoutCancel}
            sx={{ color: theme.palette.secondary.main }}
          >
            No
          </Button>
          <Button
            onClick={handleLogoutConfirm}
            sx={{ color: theme.palette.primary.main }}
          >
            {isLoggingOut ? <CircularProgress size={24} /> : "Yes"}
          </Button>
        </DialogActions>
      </Dialog>


    </>
  );
}

export default SidebarNavbar;
