import { useMediaQuery } from '@mui/material';
import React from 'react'
import { Route, Routes } from 'react-router-dom';
import TeacherAllCourses from '../../Components/TeacherSection/TeacherCourses/TeacherAllCourses';
import AdminSidebar from '../../Container/AdminSidebar/AdminSidebar';
import AdminPrivateRoute from '../PrivateRoutes/AdminPrivateRoute';
import AvailableUsers from '../../Components/AdminUserSection/AvailableUsers/AvailableUsers';
import UserDetails from '../../Components/AdminUserSection/UserDetails/UserDetails';
import CourseDetails from '../../Components/TeacherSection/TeacherCourses/CourseDetails';
import CoursePurchasedStudents from '../../Components/TeacherSection/CoursePurchasedStudents/CoursePurchasedStudents';
import ListOfTeacherBatches from '../../Components/TeacherSection/TeacherBatches/ListOfTeacherBatches';
import StudentsInBatch from '../../Components/TeacherSection/TeacherBatches/StudentsInBatch';
import AllSentMails from '../../Components/AdminUserSection/Emails/AllSentMails';
import SendNewMail from '../../Components/AdminUserSection/Emails/SendNewMail';
import AllEnquiries from '../../Components/AdminUserSection/Enquiries/AllEnquiries';
import UserAccess from '../../Components/AdminUserSection/UserAccess/UserAccess';
import ExercisesList from '../../Components/TeacherSection/Exercises/ExercisesList';
import ExerciseDetails from '../../Components/TeacherSection/Exercises/ExerciseDetails';
import ReadingContentList from '../../Components/AdminUserSection/ReadingContent/ReadingContentList';

const AdminRoutes = () => {
  const isLargeScreen = useMediaQuery("(min-width:1441px)");

  return (
    <div>
      <AdminSidebar/>
      <div
        style={{
          marginLeft: isLargeScreen ? "250px" : "0",
          flexGrow: 1,
          padding: "10px",
        }}
      >
        <Routes>
          <Route
            path="/all-available-users"
            element={<AdminPrivateRoute element={<AvailableUsers />} />}
          />
          <Route
            path="/teacher-all-courses/:id/:email"
            element={<AdminPrivateRoute element={<TeacherAllCourses />} />}
          />
          <Route
            path="/teacher-all-batches/:id/:email"
            element={<AdminPrivateRoute element={<ListOfTeacherBatches />} />}
          />
          <Route
            path="/user-details/:user_type/:id/:email"
            element={<AdminPrivateRoute element={<UserDetails />} />}
          />
          <Route
            path="/user-access/:id/:email"
            element={<AdminPrivateRoute element={<UserAccess />} />}
          />
           <Route
            path="/course-details/:id/:title"
            element={<AdminPrivateRoute element={<CourseDetails />} />}
          />
          <Route
            path="/course-enrolled-students/:id/:title"
            element={<AdminPrivateRoute element={<CoursePurchasedStudents />} />}
          />
          <Route
            path="/course-exercises/:id/:title"
            element={<AdminPrivateRoute element={<ExercisesList />} />}
          />
          <Route
            path="/students-in-batch/:id/:title"
            element={<AdminPrivateRoute element={<StudentsInBatch />} />}
          />
          <Route
            path="/all-sent-mails"
            element={<AdminPrivateRoute element={<AllSentMails />} />}
          />
          <Route
            path="/send-new-mail"
            element={<AdminPrivateRoute element={<SendNewMail />} />}
          />
          <Route
            path="/received-enquiries"
            element={<AdminPrivateRoute element={<AllEnquiries />} />}
          />
          <Route
            path="/course-exercises-details/:id/:title/:courseID"
            element={<AdminPrivateRoute element={<ExerciseDetails />} />}
          />
          <Route
            path="/reading-typing-content-list"
            element={<AdminPrivateRoute element={<ReadingContentList />} />}
          />
        </Routes>
      </div>
    </div>
  )
}

export default AdminRoutes