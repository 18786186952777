import React, { useEffect, useState } from 'react';
import { TextField, Button, Snackbar, Alert, Paper, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import ApiServices from '../../../Utils/ApiServices/ApiServices';

const StudentsInBatch = () => {
  const { id } = useParams(); 
  const { title } = useParams(); 
  console.log("batchNamebatchName",title)
  const navigate = useNavigate()
  const [batchDetails, setBatchDetails] = useState({});
  const[fetchUpdatedData,setfetchUpdatedData]=useState(false)
  const [students, setStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [updatedBatch, setUpdatedBatch] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [createMeetingOpen, setCreateMeetingOpen] = useState(false); // State to control dialog visibility
  const [recurrenceWeeks, setRecurrenceWeeks] = useState(4);
  const [schedules, setSchedules] = useState([
    { day_of_week: '', start_time: '', end_time: '' },
  ]);


  useEffect(() => {
    const fetchBatchData = async () => {
      try {
        const batchResponse = await ApiServices.get(`/api/batches/${id}/`);
        const studentResponse = await ApiServices.get(`/api/batches/${id}/assigned-courses/`);
        setBatchDetails(batchResponse.data.data);
        setStudents(studentResponse.data.data);
        setLoading(false);
      } catch (error) {
        setSnackbarMessage('Error fetching batch or student data');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setLoading(false);
      }
    };

    fetchBatchData();
  }, [id,fetchUpdatedData]);

  const handleEdit = () => {
    setEditMode(true);
    setUpdatedBatch({
      ...batchDetails,
      teacher_email: batchDetails.teacher.email // Adding teacher email
    });
    // setUpdatedBatch(batchDetails);
  };

  const handleSave = async () => {
    try {
      await ApiServices.put(`/api/batches/${id}/`, updatedBatch);
      setBatchDetails(updatedBatch); // Update state with new values
      setEditMode(false);
      setSnackbarMessage('Batch details updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setfetchUpdatedData(!fetchUpdatedData)
    } catch (error) {
      setSnackbarMessage('Error updating batch details');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleMeetingLinkClick = () => {
    window.open(batchDetails.meeting_link, '_blank'); // Navigate to the meeting link
  };

  const filteredStudents = students.filter((student) =>
    student.assigned_course.student.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle the opening and closing of the Create Meeting Dialog
  const handleCreateMeetingOpen = () => {
    setCreateMeetingOpen(true);
  };

  const handleCreateMeetingClose = () => {
    setCreateMeetingOpen(false);
  };


  const handleScheduleChange = (index, field, value) => {
    const updatedSchedules = [...schedules];

    // If the field is start_time or end_time, ensure the format is hh:mm:ss
    if (field === 'start_time' || field === 'end_time') {
      const [hours, minutes] = value.split(':');
      const formattedValue = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
      updatedSchedules[index][field] = formattedValue;
    } else {
      updatedSchedules[index][field] = value;
    }

    setSchedules(updatedSchedules);
  };

  // Add new schedule row
  const addSchedule = () => {
    setSchedules([...schedules, { day_of_week: '', start_time: '', end_time: '' }]);
  };

  // Delete a specific schedule row
  const deleteSchedule = (index) => {
    const updatedSchedules = schedules.filter((_, idx) => idx !== index);
    setSchedules(updatedSchedules);
  };

  const [startDate, setStartDate] = useState('');
const [endDate, setEndDate] = useState('');

  // Submit Meeting Request
  const handleSubmitMeeting = async () => {
    const attendees = students.map(student => student.assigned_course.student);
    const payload = {
      attendees,
      // recurrence_weeks: recurrenceWeeks,
      schedules,
      start_date: startDate, // Add start date
    end_date: endDate 
    };

    try {
      const response = await ApiServices.post('/api/create-google-schedule/', payload);
      setSnackbarMessage('Meeting created successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      console.log("responseresponseresponseresponse",response.data)
      const meetingLink = response.data.meeting_link;

    // Update batch details with teacher_email and meeting_link
    const updatedBatch = {
      ...batchDetails,
      teacher_email: batchDetails.teacher.email, // Adding teacher email
      meeting_link: meetingLink, // Adding meeting link
    };

    // Call PUT API to update batch details
    await ApiServices.put(`/api/batches/${id}/`, updatedBatch);
    setfetchUpdatedData(!fetchUpdatedData)
      setCreateMeetingOpen(false); // Close dialog after success
    } catch (error) {
      setSnackbarMessage('Error creating meeting');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSesionNavigation =()=>{
    navigate(`/teacher/sessions-of-batch/${id}/${title}`)
  }

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{ padding: '20px' }}>
      <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
        {editMode ? (
          <div>
            <TextField
              label="Batch Name"
              variant="outlined"
              fullWidth
              value={updatedBatch.batch_name}
              onChange={(e) => setUpdatedBatch({ ...updatedBatch, batch_name: e.target.value })}
              style={{ marginBottom: '20px' }}
            />
            <TextField
              label="Start Date"
              variant="outlined"
              fullWidth
              type="date"
              value={updatedBatch.start_date}
              onChange={(e) => setUpdatedBatch({ ...updatedBatch, start_date: e.target.value })}
              style={{ marginBottom: '20px' }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Expected End Date"
              variant="outlined"
              fullWidth
              type="date"
              value={updatedBatch.expected_end_date}
              onChange={(e) => setUpdatedBatch({ ...updatedBatch, expected_end_date: e.target.value })}
              style={{ marginBottom: '20px' }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Meeting Link"
              variant="outlined"
              fullWidth
              value={updatedBatch.meeting_link}
              onChange={(e) => setUpdatedBatch({ ...updatedBatch, meeting_link: e.target.value })}
              style={{ marginBottom: '20px' }}
            />
            <Button variant="contained" color="primary" onClick={handleSave} style={{ marginRight: '10px' }}>
              Save
            </Button>
            <Button variant="outlined" color="secondary" onClick={() => setEditMode(false)}>
              Cancel
            </Button>
          </div>
        ) : (
          <div>
            <Typography variant="h6" gutterBottom>
              Batch Name: {batchDetails.batch_name}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Start Date: {batchDetails.start_date}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Expected End Date: {batchDetails.expected_end_date}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Meeting Link: {batchDetails.meeting_link === null ? "Create Meeting Link" : <Button color="primary" onClick={handleMeetingLinkClick}>{batchDetails.meeting_link}</Button>}
            </Typography>
            <Button variant="contained" color="secondary" onClick={handleEdit}>
              Edit
            </Button>
          </div>
        )}
      </Paper>

        <div style={{display:"flex",justifyContent:"space-between"}}>
      <Button variant="contained" color="primary" onClick={handleCreateMeetingOpen} style={{ marginBottom: '20px' }}>
        Create Meeting
      </Button>
      <Button variant="contained" color="primary" onClick={handleSesionNavigation} style={{ marginBottom: '20px' }}>
        Session Details
      </Button>
        </div>

      <TextField
        label="Search by Student Email"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: '20px' }}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Student Email</TableCell>
              <TableCell>Joining Date</TableCell>
              <TableCell>Amount Paid</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredStudents.map((student) => (
              <TableRow key={student.id}>
                <TableCell>{student.assigned_course.student}</TableCell>
                <TableCell>{student.assigned_course.joining_date}</TableCell>
                <TableCell>{student.assigned_course.amount_paid ? `$${student.assigned_course.amount_paid}` : 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Create Meeting Dialog */}
      <Dialog open={createMeetingOpen} onClose={handleCreateMeetingClose}>
        <DialogTitle>Create Meeting</DialogTitle>
        <DialogContent>

        <TextField
      label="Start Date"
      variant="outlined"
      type="date"
      value={startDate}
      onChange={(e) => setStartDate(e.target.value)}
      style={{ marginBottom: '20px', width: '100%' }}
    />

    {/* End Date Field */}
    <TextField
      label="End Date"
      variant="outlined"
      type="date"
      value={endDate}
      onChange={(e) => setEndDate(e.target.value)}
      style={{ marginBottom: '20px', width: '100%' }}
    />
          {/* <TextField
            label="Recurrence Weeks"
            variant="outlined"
            fullWidth
            type="number"
            value={recurrenceWeeks}
            onChange={(e) => setRecurrenceWeeks(e.target.value)}
            style={{ marginBottom: '20px' }}
          /> */}
          {schedules.map((schedule, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <Select
                value={schedule.day_of_week}
                onChange={(e) => handleScheduleChange(index, 'day_of_week', e.target.value)}
                style={{ marginRight: '10px', width: '150px' }}
              >
                <MenuItem value=""><em>Select Day</em></MenuItem>
                <MenuItem value="Monday">Monday</MenuItem>
                <MenuItem value="Tuesday">Tuesday</MenuItem>
                <MenuItem value="Wednesday">Wednesday</MenuItem>
                <MenuItem value="Thursday">Thursday</MenuItem>
                <MenuItem value="Friday">Friday</MenuItem>
                <MenuItem value="Saturday">Saturday</MenuItem>
                <MenuItem value="Sunday">Sunday</MenuItem>
              </Select>
              <TextField
                label="Start Time"
                variant="outlined"
                type="time"
                value={schedule.start_time}
                onChange={(e) => handleScheduleChange(index, 'start_time', e.target.value)}
                style={{ marginRight: '10px' }}
              />
              <TextField
                label="End Time"
                variant="outlined"
                type="time"
                value={schedule.end_time}
                onChange={(e) => handleScheduleChange(index, 'end_time', e.target.value)}
                style={{ marginRight: '10px' }}
              />
              <Button variant="outlined" color="error" onClick={() => deleteSchedule(index)}>
                Delete
              </Button>
            </div>
          ))}
          <Button variant="contained" color="primary" onClick={addSchedule}>
            Add Schedule
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateMeetingClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitMeeting} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default StudentsInBatch;
