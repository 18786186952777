import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Paper, Snackbar, Alert, Divider } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SessionFilesViewer from './SessionFilesViewer ';
import ApiServices from '../../../Utils/ApiServices/ApiServices';

const SessionDetails = () => {
  const params = useParams();
  const [sessionDetails, setSessionDetails] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const LoginUserDetails = JSON.parse(sessionStorage.getItem("LoginUserDetails"));
console.log("LoginUserDetails", LoginUserDetails);

  useEffect(() => {
    const fetchSessionDetails = async () => {
      try {
        const response = await ApiServices.get(`/api/sessions/${params.sessionId}/`);
        setSessionDetails(response.data.data);
      } catch (error) {
        setSnackbar({ open: true, message: 'Failed to fetch session details', severity: 'error' });
      }
    };
    fetchSessionDetails();
  }, [params]);

  // Snackbar close handler
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box>
      {/* Check if sessionDetails is available before rendering */}
      {sessionDetails ? (
        <>
          {/* Session details section */}
          <Paper elevation={3} sx={{ p: 3, mt: 2, borderRadius: 2, backgroundColor: '#f0f8ff' }}>
            <Typography variant="h5" sx={{ mb: 2, color: '#4a90e2', textAlign: 'center', fontWeight: 'bold' }}>
              {`Batch ID: ${params.id}`}
            </Typography>

            <Typography variant="h6" sx={{ mb: 2, textAlign: 'center', color: '#ff6f61' }}>
              {params.title || 'Session Title'}
            </Typography>

            <Divider sx={{ mb: 2 }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',flexWrap:'wrap',gap:2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body1" fontWeight="bold" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CalendarTodayIcon sx={{ color: '#ffeb3b' }} /> Session Date:
                </Typography>
                <Typography variant="body1" sx={{ color: '#8bc34a' }}>
                  {sessionDetails.session_date}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body1" fontWeight="bold" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <AccessTimeIcon sx={{ color: '#03a9f4' }} /> Start Time:
                </Typography>
                <Typography variant="body1" sx={{ color: '#ff5722' }}>
                  {sessionDetails.start_time}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body1" fontWeight="bold" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <AccessTimeIcon sx={{ color: '#03a9f4' }} /> End Time:
                </Typography>
                <Typography variant="body1" sx={{ color: '#ff5722' }}>
                  {sessionDetails.end_time}
                </Typography>
              </Box>
            </Box>
          </Paper>

          {sessionDetails && sessionDetails.files && (
    <SessionFilesViewer files={sessionDetails.files} />
  )}
        </>
      ) : (
        <Typography variant="h6">Loading session details...</Typography>
      )}

      {/* Snackbar for error/success notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SessionDetails;
