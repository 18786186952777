import React, { useState, useEffect } from "react";
import AceEditor from "react-ace";
import {
  Box,
  Grid,
  Button,
  Slider,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Collapse,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import FileUploadIcon from '@mui/icons-material/FileUpload';
// Import Ace editor themes and modes
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import 'ace-builds/webpack-resolver';


const CodeSandbox = () => {
  const [htmlCode, setHtmlCode] = useState("");
  const [cssCode, setCssCode] = useState("");
  const [jsCode, setJsCode] = useState("");
  const [srcDoc, setSrcDoc] = useState("");
  const [editorHeight, setEditorHeight] = useState(200);
  const [showCssEditor, setShowCssEditor] = useState(false);
  const [showJsEditor, setShowJsEditor] = useState(false);

  // Update the srcDoc whenever htmlCode, cssCode, or jsCode changes
  useEffect(() => {
    const src = `
      <html>
        <head>
          <style>${cssCode}</style>
        </head>
        <body>
          ${htmlCode}
          <script>${jsCode}</script>
        </body>
      </html>
    `;
    setSrcDoc(src);
  }, [htmlCode, cssCode, jsCode]);

  // Function to handle file uploads
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target.result;
      const extension = file.name.split(".").pop();

      if (extension === "html") {
        setHtmlCode(content);
      } else if (extension === "css") {
        setCssCode(content);
      } else if (extension === "js") {
        setJsCode(content);
      }
    };

    reader.readAsText(file);
  };

  // Function to adjust the height of the editor
  const handleHeightChange = (e, newValue) => {
    setEditorHeight(newValue);
  };

  // Function to reset code in editors
  const resetCode = (setCodeFunction) => {
    setCodeFunction("");
  };

  // Function to download code
  const downloadCode = (code, extension) => {
    const blob = new Blob([code], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `code.${extension}`;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  return (
    <Box sx={{ height: "100%", backgroundColor: "#f4f4f4", paddingBottom: "50px" }}>
      {/* App Bar with "Run Code" Button */}
      <AppBar position="static" sx={{ backgroundColor: "#05b6b3" }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
           Luddy Buddy Code Editor
          </Typography>
          <input
            accept=".html,.css,.js"
            style={{ display: "none" }}
            id="file-upload"
            type="file"
            onChange={handleFileUpload}
          />
          <label htmlFor="file-upload">
            <Button variant="contained" component="span" color="primary">
              <FileUploadIcon/> Upload File
            </Button>
          </label>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="run"
            sx={{ ml: 2, backgroundColor: "green", color: "white" }}
          >
            <PlayArrowIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <div style={{ padding: "20px" }}>
        <Grid container spacing={3} sx={{ height: "100%" }}>
          {/* Code Editors Section */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: 2,
                boxShadow: 3,
                padding: 3,
                height: "100%",
              }}
            >
              <Typography variant="h6" gutterBottom>
                HTML, CSS, JavaScript Editors
              </Typography>

              {/* HTML Editor */}
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                HTML
              </Typography>
              <AceEditor
                mode="html"
                theme="monokai"
                value={htmlCode}
                onChange={(newCode) => setHtmlCode(newCode)}
                name="html-editor"
                editorProps={{ $blockScrolling: true }}
                width="100%"
                height={`${editorHeight}px`}
              />
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

              <Button
                variant="outlined"
                color="error"
                onClick={() => resetCode(setHtmlCode)}
                sx={{ mt: 1 }}
                disabled={!htmlCode}
              >
               <RotateLeftIcon/> Reset 
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => downloadCode(htmlCode, "html")}
                sx={{ mt: 1, ml: 1 }}
                disabled={!htmlCode}
              >
               <FileDownloadIcon/> Download 
              </Button>
              </Box>

              {/* CSS Editor with toggle */}
              <Box sx={{ mt: 2, backgroundColor: "#e0f7fa", padding: 1, borderRadius: 1 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography variant="subtitle1">CSS</Typography>
                  <IconButton onClick={() => setShowCssEditor(!showCssEditor)} aria-label="toggle css editor">
                    {showCssEditor ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Box>
              </Box>
              <Collapse in={showCssEditor}>
                <AceEditor
                  mode="css"
                  theme="monokai"
                  value={cssCode}
                  onChange={(newCode) => setCssCode(newCode)}
                  name="css-editor"
                  editorProps={{ $blockScrolling: true }}
                  width="100%"
                  height={`${editorHeight}px`}
                />
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => resetCode(setCssCode)}
                  sx={{ mt: 1 }}
                  disabled={!cssCode}
                >
                  <RotateLeftIcon/> Reset 
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => downloadCode(cssCode, "css")}
                  sx={{ mt: 1, ml: 1 }}
                  disabled={!cssCode}
                >
                 <FileDownloadIcon/> Download 
                </Button>
                </Box>
              </Collapse>

              {/* JavaScript Editor with toggle */}
              <Box sx={{ mt: 2, backgroundColor: "#e0f7fa", padding: 1, borderRadius: 1 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography variant="subtitle1">JavaScript</Typography>
                  <IconButton onClick={() => setShowJsEditor(!showJsEditor)} aria-label="toggle js editor">
                    {showJsEditor ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Box>
              </Box>
              <Collapse in={showJsEditor}>
                <AceEditor
                  mode="javascript"
                  theme="monokai"
                  value={jsCode}
                  onChange={(newCode) => setJsCode(newCode)}
                  name="js-editor"
                  editorProps={{ $blockScrolling: true }}
                  width="100%"
                  height={`${editorHeight}px`}
                />
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => resetCode(setJsCode)}
                  sx={{ mt: 1 }}
                  disabled={!jsCode}
                >
                  <RotateLeftIcon/> Reset
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => downloadCode(jsCode, "js")}
                  sx={{ mt: 1, ml: 1 }}
                  disabled={!jsCode}
                >
                 <FileDownloadIcon/> Download 
                </Button>
                </Box>
              </Collapse>

              {/* Slider to Adjust Editor Height */}
              <Box sx={{ mt: 2 }}>
                <Typography gutterBottom>Adjust Editor Height</Typography>
                <Slider
                  value={editorHeight}
                  onChange={handleHeightChange}
                  aria-labelledby="editor-height-slider"
                  min={100}
                  max={600}
                  valueLabelDisplay="auto"
                  sx={{ color: "#05b6b3" }}
                />
              </Box>
            </Box>
          </Grid>

          {/* Output Preview Section */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: 2,
                boxShadow: 3,
                padding: 3,
                height: "100%",
              }}
            >
              <Typography variant="h6" gutterBottom>
                Output Preview
              </Typography>
              <iframe
                title="output"
                srcDoc={srcDoc}
                style={{ width: "100%", height: "100%", border: "none" }}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default CodeSandbox;
