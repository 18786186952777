import React, { useState } from 'react';
import {
  Button, TextField, Checkbox, FormControlLabel, IconButton, Grid, Typography, Paper, Box, Snackbar, Alert, CircularProgress
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ApiServices from '../../../../Utils/ApiServices/ApiServices';

const SubCourseEdit = ({ subCourseData }) => {
  const params = useParams();
  
  // Initialize original data for comparison
  const initialSubCourses = subCourseData.length > 0
    ? subCourseData.map((sub) => ({
        id: sub.id || null,
        title: sub.title || '',
        file: sub.file || null,
        filePreview: sub.file || null,
        description: sub.description || '',
        active: sub.active || true,
      }))
    : [{ title: '', file: null, description: '', active: true }];

  const [subCourses, setSubCourses] = useState(initialSubCourses);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Handle input changes for dynamic subcourse form
  const handleInputChange = (index, event) => {
    const { name, value, checked, type } = event.target;
    const updatedSubCourses = [...subCourses];
    updatedSubCourses[index][name] = type === 'checkbox' ? checked : value;
    setSubCourses(updatedSubCourses);
  };

  // Add new subcourse
  const addSubCourse = () => {
    setSubCourses([...subCourses, { title: '', file: null, description: '', active: true }]);
  };

  // Delete a subcourse
  const deleteSubCourse = async (index) => {
    const subcourse = subCourses[index];
    if (subcourse.id) {
      try {
        await ApiServices.delete(`/api/courses/${params.id}/subcourses/${subcourse.id}/delete/`);
        setSnackbar({ open: true, message: 'Subcourse deleted successfully', severity: 'success' });
      } catch (error) {
        setSnackbar({ open: true, message: 'Failed to delete subcourse', severity: 'error' });
        return;
      }
    }
    const updatedSubCourses = subCourses.filter((_, i) => i !== index);
    setSubCourses(updatedSubCourses);
  };

  // Handle file change
  const handleFileChange = (index, event) => {
    const updatedSubCourses = [...subCourses];
    updatedSubCourses[index].file = event.target.files[0];
    updatedSubCourses[index].filePreview = URL.createObjectURL(event.target.files[0]);
    setSubCourses(updatedSubCourses);
  };

  // Compare current and original subcourse data to detect changes
  const getChangedFields = (subCourse, originalSubCourse) => {
    const changedFields = {};
    if (subCourse.title !== originalSubCourse.title) changedFields.title = subCourse.title;
    if (subCourse.description !== originalSubCourse.description) changedFields.description = subCourse.description;
    if (subCourse.active !== originalSubCourse.active) changedFields.active = subCourse.active;
    if (subCourse.file !== originalSubCourse.file && subCourse.file !== null) {
      changedFields.file = subCourse.file;
    }
    return changedFields;
  };

  // Submit each subcourse individually
  const handleSubmit = async () => {
    setLoading(true);
    try {
      for (let index = 0; index < subCourses.length; index++) {
        const subCourse = subCourses[index];
        const originalSubCourse = initialSubCourses[index] || {}; // Handle newly added subcourses

        const changedFields = getChangedFields(subCourse, originalSubCourse);

        // Proceed only if there are changed fields
        if (Object.keys(changedFields).length > 0) {
          const formData = new FormData();
          for (const key in changedFields) {
            formData.append(key, changedFields[key]);
          }

          // If subCourse already exists, update it, else create a new one
          if (subCourse.id) {
            await ApiServices.patch(
              `/api/courses/${params.id}/subcourses/${subCourse.id}/update/`,
              formData,
               { 'Content-Type': 'multipart/form-data' } 
            );
          } else {
            await ApiServices.post(
              `/api/courses/${params.id}/add-subcourse/`,
              formData,
               { 'Content-Type': 'multipart/form-data' } 
            );
          }
        }
      }
      setSnackbar({ open: true, message: 'Subcourses submitted successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Error submitting the form', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom align="center">Edit Subcourses</Typography>
      {subCourses.map((subCourse, index) => (
        <Paper key={index} sx={{ padding: 3, marginBottom: 2, backgroundColor: '#f5f5f5' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                value={subCourse.title}
                onChange={(e) => handleInputChange(index, e)}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="file"
                name="file"
                onChange={(e) => handleFileChange(index, e)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              {subCourse.filePreview && (
                <Box mt={2}>
                  <Typography>Preview:</Typography>
                  <a href={subCourse.filePreview} target="_blank" rel="noopener noreferrer">
                    <img src={subCourse.filePreview} alt="File Preview" width="100" />
                  </a>
                </Box>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={subCourse.description}
                onChange={(e) => handleInputChange(index, e)}
                variant="outlined"
                multiline
                rows={3}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={subCourse.active}
                    onChange={(e) => handleInputChange(index, e)}
                    name="active"
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>

            <Grid item xs={12} textAlign="right">
              <IconButton color="secondary" onClick={() => deleteSubCourse(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      ))}

      <Box textAlign="center">
        <Button variant="contained" color="primary" onClick={addSubCourse} sx={{ marginBottom: 2 }}>
          Add New Subcourse
        </Button>
        <br />
        <Button
          variant="contained"
          color="success"
          onClick={handleSubmit}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          {loading ? 'Processing...' : 'Submit'}
        </Button>
      </Box>

      {/* Snackbar for success and error messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SubCourseEdit;
