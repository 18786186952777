import React, { useState, useEffect } from 'react';
import AceEditor from 'react-ace';
import { Box, Typography, Paper, Button, Snackbar, Alert } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GetAppIcon from '@mui/icons-material/GetApp';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/worker-html';
import 'ace-builds/src-noconflict/worker-css';
import 'ace-builds/src-noconflict/worker-javascript';
import 'ace-builds/webpack-resolver';

const SessionFilesViewer = ({ files }) => {
  const [codeContents, setCodeContents] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Load file content once on mount
  useEffect(() => {
    const loadFiles = async () => {
      const contents = {};
      for (const file of files) {
        try {
          const response = await fetch(file.file);
          const content = await response.text();
          contents[file.id] = content; // Store the content with file ID as the key
        } catch (error) {
          console.error(`Failed to load file content for ${file.file}`, error);
        }
      }
      setCodeContents(contents);
    };

    if (files.length > 0) {
      loadFiles();
    }
  }, [files]);

  // AceEditor mode based on file type
  const getEditorMode = (extension) => {
    switch (extension) {
      case 'html':
        return 'html';
      case 'css':
        return 'css';
      case 'js':
        return 'javascript';
      default:
        return '';
    }
  };

  // Function to copy code to clipboard
  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      setSnackbar({ open: true, message: 'Code copied to clipboard!', severity: 'success' });
    });
  };

  // Function to download the file
  const downloadFile = (file) => {
    const content = codeContents[file.id] || ''; // Get the content to download
    const blob = new Blob([content], { type: getMimeType(file.file) }); // Create a blob with the content
    const url = URL.createObjectURL(blob); // Create a URL for the blob

    const link = document.createElement('a');
    link.href = url; // Set the blob URL
    link.download = file.file.split('/').pop(); // Set the download filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url); // Free up memory
    setSnackbar({ open: true, message: 'File downloaded!', severity: 'success' });
  };

  // Function to get MIME type based on file extension
  const getMimeType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'html':
        return 'text/html';
      case 'css':
        return 'text/css';
      case 'js':
        return 'application/javascript';
      case 'txt':
        return 'text/plain';
      case 'md':
        return 'text/markdown';
      case 'py':
        return 'text/x-python';
      case 'pdf':
        return 'application/pdf';
      default:
        return 'application/octet-stream'; // Fallback for unsupported types
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 5 }}>
      {files.length > 0 ? (
        files.map((file) => {
          const extension = file.file.split('.').pop();
          return (
            <Paper key={file.id} elevation={3} sx={{ p: 2, width: { xs: '100%', sm: 'calc(50% - 16px)' }, boxSizing: 'border-box' }}>
              {/* Flex container for title, download, and copy buttons */}
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" sx={{ mb: { xs: 1, sm: 0 } }}>
                  {file.file.split('/').pop()}
                </Typography>

                {/* Download button */}
                <Button
                  variant="outlined"
                  onClick={() => downloadFile(file)}
                  sx={{ mx: 1, width: '100%', sm: { width: 'auto' } }} // Full width on small screens
                >
                  <GetAppIcon /> Download
                </Button>

                {/* Copy button (renamed for text files) */}
                {extension === 'txt' ? (
                  <Button
                    variant="outlined"
                    onClick={() => copyToClipboard(codeContents[file.id] || '')}
                    sx={{ width: '100%', sm: { width: 'auto' } }} // Full width on small screens
                  >
                    <ContentCopyIcon /> Copy Text
                  </Button>
                ) : (
                extension !== 'pdf' && (
                  <Button
                    variant="outlined"
                    onClick={() => copyToClipboard(codeContents[file.id] || '')}
                    sx={{ width: '100%', sm: { width: 'auto' } }} // Full width on small screens
                  >
                    <ContentCopyIcon /> Copy Code
                  </Button>)
                )}
              </Box>

              {/* Code editor for supported file types */}
              {['html', 'css', 'js'].includes(extension) && (
                <AceEditor
                  mode={getEditorMode(extension)}
                  theme="monokai"
                  value={codeContents[file.id] || ''}
                  width="100%"
                  height="500px"
                  readOnly
                />
              )}

              {/* Plain text file viewer */}
              {['txt', 'md', 'py'].includes(extension) && (
                <Box sx={{ height: '500px', overflowY: 'auto', whiteSpace: 'pre-wrap', backgroundColor: '#f0f0f0', p: 2 }}>
                  <Typography>{codeContents[file.id] || ''}</Typography>
                </Box>
              )}

              {/* PDF file viewer */}
              {extension === 'pdf' && (
                <Box sx={{ height: '500px', overflowY: 'auto', border: '1px solid #ccc', p: 2 }}>
                  <iframe
                    src={file.file}
                    style={{ width: '100%', height: '100%' }}
                    title="PDF Preview"
                    frameBorder="0"
                  />
                </Box>
              )}

              {/* Fallback for unsupported file types */}
              {!['html', 'css', 'js', 'txt', 'md', 'py', 'pdf'].includes(extension) && (
                <Typography variant="body1">File preview not supported for this file type. Please Download it.</Typography>
              )}
            </Paper>
          );
        })
      ) : (
        <Typography variant="h6">No files available.</Typography>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SessionFilesViewer;
