import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Checkbox, TextField, Select, MenuItem, InputLabel, FormControl, Snackbar,
  Alert, IconButton, Dialog, DialogActions, DialogContent, DialogTitle,
  CircularProgress, Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ApiServices from '../../../Utils/ApiServices/ApiServices';

const AvailableUsers = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [userType, setUserType] = useState('teacher'); // Default filter is 'teacher'
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success'); // 'error' or 'success'
  const [fetchData, setFetchData] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingUser, setDeletingUser] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  // Fetch users from the API on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await ApiServices.get('/api/users/');
        setUsers(response.data.data);
        setFilteredUsers(response.data.data); 
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, [fetchData]);

  
  useEffect(() => {
    let updatedUsers = users;

    
    if (userType !== 'show_all') {
      updatedUsers = users.filter(user => user.user_type === userType);
    }

   
    if (searchTerm) {
      updatedUsers = updatedUsers.filter(
        user =>
          user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredUsers(updatedUsers);
  }, [searchTerm, userType, users]);

  // Handle checkbox change
  const handleCheckboxChange = async (user, field) => {
    const updatedValue = !user[field];
    try {
      await ApiServices.patch(`/api/update-user/${user.email}/`, { [field]: updatedValue });
      setSnackbarMessage(`User ${user.first_name} ${user.last_name}'s ${field.replace(/_/g, ' ')} updated successfully.`);
      setAlertSeverity('success');
      setFetchData(!fetchData);
    } catch (error) {
      setSnackbarMessage(`Error updating ${user.first_name} ${user.last_name}'s ${field.replace(/_/g, ' ')}.`);
      setAlertSeverity('error');
    }
    setSnackbarOpen(true);
  };

  // Open delete dialog
  const handleDeleteClick = (user) => {
    setDeletingUser(user);
    setDeleteDialogOpen(true);
  };

  // Confirm delete user
  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      await ApiServices.delete(`/api/delete-user/${deletingUser.email}/`);
      setSnackbarMessage(`User ${deletingUser.first_name} ${deletingUser.last_name} deleted successfully.`);
      setAlertSeverity('success');
      setFetchData(!fetchData);
    } catch (error) {
      setSnackbarMessage(`Error deleting ${deletingUser.first_name} ${deletingUser.last_name}.`);
      setAlertSeverity('error');
    }
    setLoading(false);
    setDeleteDialogOpen(false);
    setSnackbarOpen(true);
  };

  // Close snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Close delete dialog
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  // Navigate to user details page
  const handleShowProfile = (user) => {
    navigate(`/admin-user/user-details/${user.user_type}/${user.id}/${user.email}`);
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        {/* Search Bar */}
        <TextField
          label="Search by Name or Email"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: '60%' }}
        />

        {/* User Type Filter Dropdown */}
        <FormControl style={{ width: '35%' }}>
          <InputLabel>User Type</InputLabel>
          <Select
            value={userType}
            onChange={(e) => setUserType(e.target.value)}
            label="User Type"
          >
            <MenuItem value="teacher">Teacher</MenuItem>
            <MenuItem value="student">Student</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="manager">Manager</MenuItem>
            <MenuItem value="show_all">Show All</MenuItem>
          </Select>
        </FormControl>
      </div>

      {/* Table to display users */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>User Type</TableCell>
              <TableCell>Is Email Verified</TableCell>
              <TableCell>Is Account Verified</TableCell>
              <TableCell>Is Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.length > 0 ? (
              filteredUsers.map(user => (
                <TableRow key={user.id}>
                  <TableCell                       onClick={() => handleShowProfile(user)}
>{user.first_name}</TableCell>
                  <TableCell>{user.last_name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phone_number}</TableCell>
                  <TableCell>{user.user_type}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={user.is_email_verified}
                      onChange={() => handleCheckboxChange(user, 'is_email_verified')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={user.is_account_verified}
                      onChange={() => handleCheckboxChange(user, 'is_account_verified')}
                      disabled={user.user_type === "student"}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={user.is_active}
                      onChange={() => handleCheckboxChange(user, 'is_active')}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteClick(user)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    {/* Show Profile Button */}
                    {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleShowProfile(user)}
                      sx={{ ml: 1 }}
                    >
                      Show Profile
                    </Button> */}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                  No users found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={alertSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          {deletingUser && (
            <div>
              <p>Are you sure you want to delete the user {deletingUser.first_name} {deletingUser.last_name}?</p>
              <p>Email: {deletingUser.email}</p>
              <p>Phone Number: {deletingUser.phone_number}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} disabled={loading}>
            {loading && <CircularProgress size={24} />}
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AvailableUsers;
