import $ from "jquery";
import ReactDOM from "react-dom";
import "jquery-ui-sortable";
import React, { Component, createRef, useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import ApiServices from "../../Utils/ApiServices/ApiServices.js";

window.jQuery = $;
require("formBuilder");
require("../../../node_modules/formBuilder/dist/form-render.min.js");

const FormBuilder = () => {

  const [formBuilderDisplay, setFormBuilderDisplay] = useState(false);
  const [formName, setFormName] = useState("");
  const [rewardPoints, setRewardPoints] = useState(0);
  const [formFrequency, setFormFrequency] = useState(0);
  const [deadline, setDeadline] = useState(0);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [formBuilderJson, setFormBuilderJson] = useState("");

  const fb = createRef();

  useEffect(() => {
    initializeFormBuilder();
  }, []);

  const initializeFormBuilder = () => {
    const formBuilder = $(ReactDOM.findDOMNode(fb.current)).formBuilder();
    setFormBuilderDisplay({ formBuilder });

    // document.getElementById('createNewFormButton').addEventListener('click', showFormBuilder);
    document
      .getElementById("submit")
      .addEventListener("click", () => handleSave(formBuilder));
  };

  const handleSave = (formBuilder) => {
    //alert(JSON.stringify(formBuilder.formData));
    //const jsonData = formBuilder.actions.getData('json', true);
    //console.log('JSON Data:', jsonData);
    if (formBuilder && formBuilder.formData) {
      //alert(JSON.stringify(formBuilder.formData));
      setSaveButtonClicked(true);
      setFormBuilderJson(formBuilder.formData);
    } else {
      console.error("formBuilder or formBuilder.formData is not defined.");
    }
  };

  const handleClose = () => {
    setSaveButtonClicked(false);
  };


  const showFormBuilder = () => {
    setFormBuilderDisplay(true);
  };

  const handleFormSave = async () => {
    try {
      const payload = {
        form_schema: formBuilderJson,
        form_name: formName,
        form_frequency: formFrequency,
        deadline: deadline,
        active: true,
        category: "Quiz",
      };

      const response = await ApiServices.post(
        "/formbuilder/forms/create/",
        payload
      );
      console.log("Form saved successfully:", response.data);
      setSaveButtonClicked(false);
    } catch (error) {
      console.error("Error saving form:", error);
    }
  };

  const renderFormDialog = () => (
    <Dialog
      open={saveButtonClicked && formBuilderDisplay}
      onClose={handleClose}
    >
      <DialogTitle>Form Details</DialogTitle>
      <DialogContent>
        <TextField
          label="Form Name"
          value={formName}
          onChange={(e) => setFormName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Form Frequency"
          type="number"
          value={formFrequency}
          onChange={(e) => setFormFrequency(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Deadline for Form Filling"
          type="number"
          value={deadline}
          onChange={(e) => setDeadline(e.target.value)}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleFormSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div style={{ backgroundColor: "white", padding: "10px" }}>
      <h2>Create your own Quiz</h2>
      <div className="form-buttons">
        <button id="submit" onClick={handleSave}>
          Save
        </button>
        <button onClick={handleClose}>Close</button>
      </div>
      <div>{showFormBuilder && <div id="fb-editor" ref={fb} />}</div>
      {renderFormDialog()}
    </div>
  );
};

export default FormBuilder;
