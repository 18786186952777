import React from 'react'
import { Route, Routes } from 'react-router-dom'
import HomePage from '../../Components/WebSitePages/HomePage/HomePage'
import CoursesPage from '../../Components/WebSitePages/CoursesPage/CoursesPage'
import ContactPage from '../../Components/WebSitePages/ContactPage/ContactPage'
import AboutUsPage from '../../Components/WebSitePages/AboutUsPage/AboutUsPage'
import StudentRegistration from '../../Components/RegistrationPages/StudentRegistration/StudentRegistration'
import TeacherRegistration from '../../Components/RegistrationPages/TeacherRegistration/TeacherRegistration'
import SignInPage from '../../Components/RegistrationPages/SignInPage/SignInPage'
import WebNavbar from '../../Components/WebSitePages/WebNavbar/WebNavbar'
import SpeedReading from '../../Components/WebSitePages/SpeedReading/SpeedReading'
import CourseDetailPage from '../../Components/WebSitePages/CourseDetail/CourseDetail'
import TypingTest from '../../Components/WebSitePages/TypingTest/TypingTest'
import WebFooter from '../../Components/WebSitePages/WebFooter/WebFooter'
import PrivacyPolicy from '../../Components/WebSitePages/Policies/PrivacyPolicy'
import ServiceTerms from '../../Components/WebSitePages/Policies/ServiceTerms'

const WebSiteRoutes = () => {
  return (
    <div>
    <WebNavbar/>

      <Routes>
        <Route path="/" element={<HomePage />}/>
        <Route path="/our-courses" element={<CoursesPage />}/>
        <Route path="/contact-us" element={<ContactPage />}/>
        <Route path="/about-us" element={<AboutUsPage />}/>
        <Route path="/student-sign-up" element={<StudentRegistration />}/>
        <Route path="/teacher-sign-up" element={<TeacherRegistration />}/>
        <Route path="/sign-in" element={<SignInPage />}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
        <Route path="/service-terms" element={<ServiceTerms />}/>
        <Route path="/course-detail/:id/:title" element={<CourseDetailPage />}/>
        <Route path="/typing-test" element={<TypingTest />}/>
      </Routes>

      <WebFooter/>
    </div>
  )
}

export default WebSiteRoutes