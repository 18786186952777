import React, { useState } from 'react';
import axios from 'axios';
import {
  TextField, Checkbox, Select, MenuItem, Button,
  Snackbar, Alert, FormControlLabel, CircularProgress
} from '@mui/material';
import ApiServices from '../../../Utils/ApiServices/ApiServices';

const AddReadingContent = ({ onAddSuccess }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [active, setActive] = useState(true);
  const [level, setLevel] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(false); // Loading state

  const handleAddContent = async () => {
    // Validate required fields
    if (!title || !content || !level) {
      setSnackbar({ open: true, message: 'Please fill in all fields', severity: 'error' });
      return;
    }

    const newContent = {
      title,
      content,
      active,
      level,
    };

    setLoading(true); // Set loading to true

    try {
      await ApiServices.post('/api/speedreading/data/', newContent);
      setSnackbar({ open: true, message: 'Content added successfully', severity: 'success' });
      setTitle('');
      setContent('');
      setActive(false);
      setLevel('');
      onAddSuccess();  // Fetch updated data after adding new content
    } catch (error) {
      console.error("Error adding content:", error);
      setSnackbar({ open: true, message: 'Error adding content', severity: 'error' });
    } finally {
      setLoading(false); // Reset loading state after API call
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <h3>Add New Reading Content</h3>
      <TextField
        label="Title"
        variant="outlined"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Content"
        variant="outlined"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      
      <Select
        value={level}
        onChange={(e) => setLevel(e.target.value)}
        displayEmpty
        fullWidth
        variant="outlined"
        margin="normal"
      >
        <MenuItem value="" disabled>
          Select Level
        </MenuItem>
        <MenuItem value="easy">Easy</MenuItem>
        <MenuItem value="medium">Medium</MenuItem>
        <MenuItem value="hard">Hard</MenuItem>
      </Select>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddContent}
        style={{ marginTop: '10px' }}
        disabled={loading} // Disable button while loading
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Add Content'} {/* Show progress indicator */}
      </Button>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddReadingContent;
