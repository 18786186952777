import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Snackbar } from '@mui/material';
import ApiServices from '../../../Utils/ApiServices/ApiServices';

const AddNewPdf = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle file input change
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Function to handle PDF upload
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      setErrorMessage('Please select a PDF file to upload.');
      return;
    }

    // Prepare form data
    const formData = new FormData();
    formData.append('pdf_file', selectedFile);
    formData.append('email', 'sahilpatil7309@gmail.com')

    try {
      setIsSubmitting(true);

      // Send POST request to upload PDF
      const response = await ApiServices.post('/api/pdf-documents/', formData, {
        
          'Content-Type': 'multipart/form-data',

      });

      // Handle success response
      if (response.status === 201) {
        setSuccessMessage('PDF uploaded successfully!');
        setSelectedFile(null); // Reset file input
      } else {
        setErrorMessage('Failed to upload the PDF.');
      }
    } catch (error) {
      console.error('Error uploading PDF:', error);
      setErrorMessage('An error occurred while uploading the PDF.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <h2>Upload a New PDF</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
          style={{ marginBottom: '20px' }}
        />
        <br />
        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
          {isSubmitting ? 'Uploading...' : 'Upload PDF'}
        </Button>
      </form>

      {/* Success Snackbar */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        message={successMessage}
      />

      {/* Error Snackbar */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
    </div>
  );
};

export default AddNewPdf;
