import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../Assets/cosmos  Logo.png"
import SidebarNavbar from "../SidebarNavbar/SidebarNavbar";
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FactCheckIcon from '@mui/icons-material/FactCheck';

export default function TeacherSidebar() {
  const [state, setState] = React.useState({
    left: false,
    top: false,
  });

  const isLargeScreen = useMediaQuery("(min-width:1441px)");
  const location = useLocation();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        background: "#48CFCB",
        height: "100%",
        // borderTopRightRadius: isLargeScreen ? 30 : 0,
        // borderBottomRightRadius: 30,
        overflowX: "hidden", 
      }}
      role="presentation"
      onClick={isLargeScreen ? null : toggleDrawer(anchor, false)}
      onKeyDown={isLargeScreen ? null : toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 65
        }}
      >
        <img src={Logo} alt="Hospital Logo" style={{ maxWidth: "100%", maxHeight: 55 }} />
      </Box>
      <Divider />
      <List>
        {[
          { text: "Create Course", icon: <EditNoteIcon />, path: "/teacher/teacher-create-courses" },
          { text: "My Courses", icon: <FactCheckIcon />, path: "/teacher/teacher-all-courses" },
          { text: "My Batches", icon: <CastForEducationIcon />, path: "/teacher/all-avalable-batches" }
        ].map((item) => (
          <ListItem key={item.text} disablePadding 
            sx={{
                  backgroundColor: location.pathname === item.path ? 'rgba(0,0,0,0.2)' : 'inherit', width:"100%",
                   color:"white",
                  '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.2)',
                },
                }}
          >
            <Link to={item.path} style={{ textDecoration: "none", color: "inherit", width: '100%' }}>
              <ListItemButton
                onClick={isLargeScreen ? null : toggleDrawer(anchor, false)}
              
              >
                <ListItemIcon sx={{color:"white"}}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <SidebarNavbar toggleDrawer={toggleDrawer} />
      {isLargeScreen ? (
        <Drawer
          variant="permanent"
          anchor="left"
          open
          sx={{
            '& .MuiDrawer-paper': {
              width: 250,
              boxSizing: 'border-box',
              overflowX: 'hidden', 
            },
          }}
        >
          {list("left")}
        </Drawer>
      ) : (
        <SwipeableDrawer
          anchor="top"
          open={state.top}
          onClose={toggleDrawer("top", false)}
          onOpen={toggleDrawer("top", true)}
          sx={{
            '& .MuiDrawer-paper': {
              borderBottomLeftRadius: 30,
              borderBottomRightRadius: 30,
              background: 'linear-gradient(135deg, #ffcbc9 0%, #fea5b8 30%, #e990c6 60%, #c196c1 100%)',
              overflowX: 'hidden', 
            },
          }}
        >
          {list("top")}
        </SwipeableDrawer>
      )}
    </div>
  );
}
