import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiServices from "../../../Utils/ApiServices/ApiServices";

const UserAccess = () => {
  const params = useParams(); // Get ID from route parameters
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allFrontendRoutes, setallFrontendRoutes] = useState([]);
  const [userAccessRoutes, setUserAccessRoutes] = useState([]);

  console.log("useruser",user)
  console.log("allFrontendRoutes",allFrontendRoutes)
  console.log("userAccessRoutes",userAccessRoutes)

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await ApiServices.get(`/api/users/${params.id}/`);
        setUser(response.data.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [params]);
 
  useEffect(() => {
    const fetchRoutesData = async () => {
      try {
        const response = await ApiServices.get(`/api/routes/`);
        setallFrontendRoutes(response.data.data);
      } catch (error) {
        console.error("Error fetching Routes:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUserRoutesData = async () => {
      try {
        const response = await ApiServices.get(
          `api/routes/user/${params.email}`
        );
        setUserAccessRoutes(response.data.data);
      } catch (error) {
        console.error("Error fetching Routes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRoutesData();
    fetchUserRoutesData();
  }, [params]);

  return <div>UserAccess</div>;
};

export default UserAccess;
