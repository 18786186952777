import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import ApiServices from '../../../Utils/ApiServices/ApiServices'; // Assuming you have ApiServices set up for API calls
import axios from 'axios';

const ListOfTeacherBatches = () => {
  const [batches, setBatches] = useState([]);
  const [filteredBatches, setFilteredBatches] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [userType, setUserType] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [batchToDelete, setBatchToDelete] = useState(null); // State for selected batch to delete
  const navigate = useNavigate();
  const { email: emailFromParams } = useParams(); // Get email from URL params

  useEffect(() => {
    const fetchEmail = () => {
      if (emailFromParams) {
        return emailFromParams; // Use email from URL params if available
      }
      const userDetails = JSON.parse(sessionStorage.getItem('LoginUserDetails'));
      return userDetails?.email;
    };

    const userDetails = JSON.parse(sessionStorage.getItem('LoginUserDetails'));
    setUserType(userDetails?.user_type);
    const email = fetchEmail();

    const fetchBatches = async () => {
      try {
        const response = await ApiServices.get(`/api/batches/by-email/${email}/`);
        setBatches(response.data.data);
        setFilteredBatches(response.data.data); // Set filtered data initially to all batches
      } catch (error) {
        console.error("Error fetching batches:", error);
      }
    };

    fetchBatches();
  }, [emailFromParams]); // Re-run effect if emailFromParams changes

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = batches.filter((batch) =>
      batch.batch_id.toLowerCase().includes(query) ||
      batch.batch_name.toLowerCase().includes(query)
    );
    setFilteredBatches(filtered);
  };

  const handleBatchClick = (batch_id, batch_name) => {
    if (userType === 'teacher') {
      navigate(`/teacher/students-in-batch/${batch_id}/${batch_name}`);
    } else if (userType === 'admin') {
      navigate(`/admin-user/students-in-batch/${batch_id}/${batch_name}`);
    }
  };

  const handleCreateBatch = () => {
    navigate('/teacher/create-new-batch'); // Navigating to create batch page
  };

  const handleMeetingLinkClick = (link) => {
    window.open(link, '_blank'); // Open meeting link in a new tab
  };

  // Open delete confirmation dialog
  const handleDeleteClick = (batch) => {
    setBatchToDelete(batch);
    setOpenDialog(true);
  };

  // Confirm deletion
  const handleConfirmDelete = async () => {
    try {
      await ApiServices.delete(`/api/batches/${batchToDelete.batch_id}/delete/`); // Call delete API
      setBatches((prevBatches) => prevBatches.filter(batch => batch.batch_id !== batchToDelete.batch_id));
      setFilteredBatches((prevFiltered) => prevFiltered.filter(batch => batch.batch_id !== batchToDelete.batch_id));
      setOpenDialog(false); // Close the dialog
    } catch (error) {
      console.error("Error deleting batch:", error);
    }
  };

  // Close delete confirmation dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setBatchToDelete(null);
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Teacher Batches</h2>

      {/* Search Bar and Create Batch Button */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <TextField
          label="Search by Batch ID or Batch Name"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          fullWidth
          style={{ marginRight: '20px' }}
        />
        <Button variant="contained" color="primary" onClick={handleCreateBatch} disabled={userType !== 'teacher'}>
          Create Batch
        </Button>
      </div>

      {/* Conditional rendering based on the number of batches */}
      {filteredBatches.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Batch ID</TableCell>
                <TableCell>Batch Name</TableCell>
                <TableCell>Meeting Link</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>Expected End Date</TableCell>
                <TableCell>Active Status</TableCell>
                <TableCell>Actions</TableCell> {/* New Actions column */}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredBatches.map((batch) => (
                <TableRow key={batch.batch_id} onClick={() => handleBatchClick(batch.batch_id, batch.batch_name)} style={{ cursor: 'pointer' }}>
                  <TableCell>{batch.batch_id}</TableCell>
                  <TableCell>{batch.batch_name}</TableCell>
                  <TableCell>
                    {batch.meeting_link === null ? "No meetings Created" : (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering the row click when opening the meeting link
                          handleMeetingLinkClick(batch.meeting_link);
                        }}
                      >
                        Open Meeting Link
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>{batch.start_date}</TableCell>
                  <TableCell>{batch.expected_end_date}</TableCell>
                  <TableCell>{batch.is_active ? 'Active' : 'Inactive'}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the row click when opening the dialog
                        handleDeleteClick(batch);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="h6" color="textSecondary" align="center">
          No batches created by you.
        </Typography>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete the batch?</Typography>
          {batchToDelete && (
            <div>
              <Typography><strong>Batch ID:</strong> {batchToDelete.batch_id}</Typography>
              <Typography><strong>Batch Name:</strong> {batchToDelete.batch_name}</Typography>
              <Typography><strong>Meeting Link:</strong> {batchToDelete.meeting_link || 'No meetings created'}</Typography>
              <Typography><strong>Start Date:</strong> {batchToDelete.start_date}</Typography>
              <Typography><strong>Expected End Date:</strong> {batchToDelete.expected_end_date}</Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ListOfTeacherBatches;
