import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import StudentRoutes from './Utils/Routes/StudentRoutes';
import TeacherRoutes from './Utils/Routes/TeacherRoutes';
import WebSiteRoutes from './Utils/Routes/WebSiteRoutes';
import AdminRoutes from './Utils/Routes/AdminRoutes';
import FormBuilder from './Components/FormBuilder/FormBuilder';
import ScrollToTop from './Utils/Routes/ScrollToTop';


function App() {
  return (
    <div>
    <ScrollToTop/>
      <Routes>
        <Route path="/*" element={<WebSiteRoutes />} />
        <Route path="/student/*" element={<StudentRoutes />} />
        <Route path="/teacher/*" element={<TeacherRoutes />} />
        <Route path="/admin-user/*" element={<AdminRoutes />} />
        <Route path="/form-builder" element={<FormBuilder />} />
      </Routes>
    </div>
  );
}

export default App;
