import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Snackbar, Alert, IconButton, Box, Typography, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Paper, Collapse
} from '@mui/material';
import { Add, Delete, ExpandMore, ExpandLess } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ApiServices from '../../../Utils/ApiServices/ApiServices';

const LectureSessionsList = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [batchId, setBatchId] = useState(params.id); // Getting batch_id from params
  const [sessionDate, setSessionDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [files, setFiles] = useState([{ key: '', file: null }]); // Dynamic file list
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [sessions, setSessions] = useState([]);
  const [filterDate, setFilterDate] = useState(''); // For filtering sessions by date
  const [isFormOpen, setIsFormOpen] = useState(false); // To toggle form visibility
  const [fetchNewSessionData, setfetchNewSessionData] = useState(false); // To toggle form visibility
  const LoginUserDetails = JSON.parse(sessionStorage.getItem("LoginUserDetails"));
  const userType = LoginUserDetails?.user_type;
console.log("LoginUserDetails", LoginUserDetails);
  // Fetch sessions based on batch ID
  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await ApiServices.get(`/api/sessions/batch/${batchId}/`);
        setSessions(response.data.data);
      } catch (error) {
        setSnackbar({ open: true, message: 'Failed to fetch sessions', severity: 'error' });
      }
    };
    fetchSessions();
  }, [batchId,fetchNewSessionData]);

  // Handle dynamic file inputs
  const handleFileChange = (index, event) => {
    const newFiles = [...files];
    newFiles[index].file = event.target.files[0];
    setFiles(newFiles);
  };

  const handleAddFileInput = () => {
    setFiles([...files, { key: '', file: null }]);
  };

  const handleRemoveFileInput = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  // Snackbar handling
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Toggle form visibility
  const handleToggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  // POST request using Axios
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('batch_id', batchId);
    formData.append('session_date', sessionDate);
    formData.append('start_time', startTime);
    formData.append('end_time', endTime);
    
    files.forEach((file, index) => {
      if (file.file) {
        formData.append('session_files', file.file); // Add each file
      }
    });

    try {
      await ApiServices.post('/api/sessions/', formData, {
        
          'Content-Type': 'multipart/form-data',
        
      });
      setSnackbar({ open: true, message: 'Session created successfully!', severity: 'success' });
      setIsFormOpen(false); // Hide form after successful submission
      setfetchNewSessionData(!fetchNewSessionData)
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to create session!', severity: 'error' });
    }
  };

  // Filter sessions by date
  const filteredSessions = sessions.filter((session) =>
    session.session_date.includes(filterDate)
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>Lecture Sessions - {params.id} ({params.title})</Typography>

      {/* Button to toggle the form */}
      {LoginUserDetails.user_type !== "student" &&
      <Button
        variant="contained"
        color="primary"
        startIcon={isFormOpen ? <ExpandLess /> : <ExpandMore />}
        onClick={handleToggleForm}
        sx={{ mb: 2 }}
      >
        {isFormOpen ? 'Hide Create Lecture Session' : 'Create Lecture Session'}
      </Button>
      }
      {/* Collapsible form */}
      <Collapse in={isFormOpen}>
        <Box sx={{ mb: 4 }}>
          <TextField
            label="Batch ID"
            value={batchId}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Session Date"
            type="date"
            value={sessionDate}
            onChange={(e) => setSessionDate(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />
          <TextField
            label="Start Time"
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />
          <TextField
            label="End Time"
            type="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />
          <Box>
            {files.map((file, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <TextField
                  type="file"
                  onChange={(e) => handleFileChange(index, e)}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
                {index > 0 && (
                  <IconButton onClick={() => handleRemoveFileInput(index)}>
                    <Delete />
                  </IconButton>
                )}
              </Box>
            ))}
            <Button
              variant="outlined"
              startIcon={<Add />}
              sx={{ mt: 2 }}
              onClick={handleAddFileInput}
            >
              Add Another File
            </Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 4 }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Collapse>

      {/* Filter and display sessions */}
      <TextField
        label="Filter by Date"
        value={filterDate}
        onChange={(e) => setFilterDate(e.target.value)}
        fullWidth
        margin="normal"
      />

      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Session Date</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSessions.map((session) => (
                <TableRow
          key={session.id}
          hover
          onClick={() => {
            let basePath;

            // Determine the base path based on user type
            if (userType === 'student') {
              basePath = `/student/sessions-details`;
            } else if (userType === 'teacher') {
              basePath = `/teacher/sessions-details`;
            } else if (userType === 'admin') {
              basePath = `/admin-user/sessions-details`;
            } else {
              console.error("Unknown user type");
              return; // Optionally handle unknown user types
            }

            // Navigate to the constructed path
            navigate(`${basePath}/${batchId}/${params.title}/${session.id}`);
          }}
        >
                <TableCell>{session.session_date}</TableCell>
                <TableCell>{session.start_time}</TableCell>
                <TableCell>{session.end_time}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Snackbar for success and error messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LectureSessionsList;
