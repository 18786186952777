import React, { useEffect } from 'react';
import { Grid, TextField, Button, Typography, Box, Card, CardMedia } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import image1 from "../../../Assets/support-illustration/5118756.jpg";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const Contact = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  return (
    <div id="contact" style={{ padding: '50px 0', marginTop:'0px' }}>
      <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 20px' }}>
        <Grid container spacing={4}>
          
          {/* Left Section */}
          <Grid item xs={12} md={4}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{ fontWeight: 'bold', color: '#00308f', textAlign: 'center' }}
              data-aos="fade-up" // AOS effect
            >
              Contact Us
            </Typography>
            <Box
              sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
              data-aos="fade-up" data-aos-delay="200" // AOS effect with delay
            >
              <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
                <CardMedia
                  component="img"
                  image={image1}
                  alt="Learning"
                  sx={{ borderRadius: 2, objectFit: "cover", height: '300px', width: '100%' }}
                />
              </Card>
            </Box>

            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}
              data-aos="fade-up" data-aos-delay="400"
            >
              <MailIcon sx={{ marginRight: '8px', color: '#00308f' }} />
              <Typography component="a" href="mailto:luddy_buddy@gmail.com" style={contactLinkStyle}>
                luddy_buddy@gmail.com
              </Typography>
            </Box>

            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}
              data-aos="fade-up" data-aos-delay="300"
            >
              <CallIcon sx={{ marginRight: '8px', color: '#00308f' }} />
              <Typography variant="body1">
                <a href="tel:1234567890" style={contactLinkStyle}>
                  +1234567890
                </a>
              </Typography>
            </Box>

            <Box
              sx={{ display: 'flex', justifyContent: 'center', gap: '15px', marginTop: '20px' }}
              
            >
              <a href="https://www.linkedin.com/in/parag-shinde-093144129/" target="_blank" rel="noopener noreferrer" style={socialLinkStyle}>
                <LinkedInIcon sx={{ fontSize: '50px', color: 'black' }} />
              </a>
              <a href="https://www.instagram.com/parag3195?r=nametag" target="_blank" rel="noopener noreferrer" style={socialLinkStyle}>
                <InstagramIcon sx={{ fontSize: '50px', color: 'black' }} />
              </a>
            </Box>
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} md={8} data-aos="fade-up">
            <form name="submit-to-google-sheet">
              <TextField
                label="Your Name"
                variant="outlined"
                name="Name"
                fullWidth
                required
                margin="normal"
              />
              <TextField
                label="Your Email"
                variant="outlined"
                type="email"
                name="Email"
                fullWidth
                required
                margin="normal"
              />
              <TextField
                label="Your Message"
                variant="outlined"
                name="Message"
                multiline
                rows={6}
                fullWidth
                margin="normal"
              />
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ marginTop: '20px', padding: '12px 20px', fontSize: '18px', backgroundColor: '#ff6600' }}
              >
                Submit
              </Button>
            </form>
          </Grid>
        </Grid>

        {/* Footer */}
        <div style={copyrightStyle} >
          <Typography variant="body2">Copyright © Luddy_Buddy</Typography>
        </div>
      </div>
    </div>
  );
};

const contactLinkStyle = {
  display: 'block',
  textDecoration: 'none',
  fontSize: '16px',
  padding: '10px 0',
  color: '#00308f',
  textAlign: 'center'
};

const socialLinkStyle = {
  textDecoration: 'none',
  fontSize: '18px',
  marginRight: '15px',
  display: 'inline-block',
  transition: 'color 0.5s',
};

const copyrightStyle = {
  marginTop: '20px',
  textAlign: 'center',
  color: '#ababab',
};

export default Contact;
